@import url(https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600&family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container{margin:0 auto; width:1120px;}
.container-lg{margin:0 auto; width:1240px;}
.half-container{width:calc(100% - 560px);}

.main-content{padding-bottom:35px; background:rgb(215,232,244); background:linear-gradient(170deg, rgba(215,232,244,1) 0%, rgba(255,255,255,1) 100%);}
.main-content-full{}

.main-content-title{margin-bottom:25px}
.main-content-title h1{font-weight:400; font-size:30px; color:#003965; margin:0}
.main-content-title .page-subtitle{color:#6E939C; font-weight:normal}

.homepage .main-content{padding:0; background:#fff}

.items-end{justify-content:flex-end;}
.self-center{margin:0 auto; align-self:center}
.self-right{align-self:flex-end}

.d-flex{display:flex !important;}
.d-flex-column{display:flex; flex-direction:column;}
.rev-row{display:flex; flex-direction:row-reverse;}
.rev-column{display:flex; flex-direction:column-reverse;}
.justify-center{display:flex; align-items:center; justify-content:space-between;}
.justify-content-between{justify-content:space-between !important;}
.justify-content-center{justify-content:center !important;}
.justify-content-start{justify-content:flex-start !important;}
.justify-content-end{justify-content:flex-end !important;}
.align-start{}
.align-center{display:flex; justify-content:center; align-items:center;}
.text-center{text-align:center;}
.text-left{text-align:left;}
.text-right{text-align:right;}
.vertical{flex-direction:column;}

.ant-radio-checked::after{border:1px solid #003965}
.ant-radio-inner::after{background-color:#003965;}
.ant-radio-checked .ant-radio-inner{border-color:#003965}

.ant-input{background-color:#F8F8F8; font-size:16px; padding:8px 15px;}
.ant-input:hover,
.ant-picker:hover,
.ant-picker-focused,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-input-number:hover{border-color:#003965}

img,
.img-fluid{max-width:100%;}

a{color:#236eaf; text-decoration:none;}
a:hover{color:inherit;}

.card{padding:35px; background-color:#fff; box-shadow:0 10px 20px rgba(0,0,0,.2)}

.ant-btn{height:auto}
.ant-btn-primary{background-color:#003965; border:1px solid #003965; border-radius:5px; text-transform:uppercase;}
.ant-btn-primary:hover{background-color:#fff; border:1px solid #003965; color:#003965}

.ant-btn-secondary{background-color:#fff; border:1px solid #003965; color:#003965; text-transform:uppercase;}
.ant-btn-secondary:hover{background-color:rgb(0, 57, 101, .2); border:1px solid #003965; color:#003965}

.btn-orange{background-color:#ee7d22; border:1px solid #ee7d22; font-weight:bold}
.btn-orange:hover{background-color:#fff; border:1px solid #ee7d22; color:#ee7d22}

.header-alerts .ant-alert-with-description{position:fixed; right:15px; top:15px; width:500px; z-index:99999; box-shadow:0 0 15px rgba(0,0,0,.05);}

.ant-btn-lg{padding:10px 25px; height:auto}
.btn-wide{max-width:50%; width:100%}

input,
select,
textarea{border:1px solid #e3e3e3; border-radius:4px;}
.form-control{color:#adaaaa; font-size:14px; padding:10px 15px; width:100%; margin-bottom:15px;}
.note{color:#adaaaa; font-size:12px; margin-bottom:15px;}
.form-item-block.d-flex{justify-content:space-between; align-items:flex-start;}
.form-item-block.d-flex .form-group{width:49%}
.form-item-block.d-flex .w-25{width:23%;}
.form-item-block.d-flex .w-33{width:32%;}
.form-item-block.d-flex .w-50{width:48%;}
.form-item-block.d-flex .w-66{width:66%;}
.form-item-block.d-flex .w-100{width:100%;}

.ant-input:-ms-input-placeholder{color:#bfbfbf;}

.ant-input::placeholder{color:#bfbfbf;}
.ant-input-number-input:-ms-input-placeholder{color:#bfbfbf;}
.ant-input-number-input::placeholder{color:#bfbfbf;}

.ant-select-multiple .ant-select-selector{border:1px solid #F8F8F8; border-left:3px solid #F8F8F8; background-color:#F8F8F8}

.ant-input-number{border:1px solid #F8F8F8; border-left:3px solid #F8F8F8}
.ant-input-affix-wrapper{background-color:#F8F8F8; border:1px solid #F8F8F8; border-left:3px solid #F8F8F8}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{background-color:#f8f8f8; border:1px solid #f8f8f8}

.ant-input-affix-wrapper:hover{border-color:#003965; border-left:3px solid #003965;}
.ant-input-affix-wrapper:focus{border-color:#fff; border-left:3px solid #F07E22;}

.ant-input-affix-wrapper-focused.ant-input-affix-wrapper:hover{border-color:#fff; border-left:3px solid #F07E22;}

.ant-input,
.ant-input-number-input{background-color:#F8F8F8; /*border:1px solid #F8F8F8; border-left:3px solid #F8F8F8;*/ position:relative;}
.ant-form-item-label > label{color:#003965}
.ant-input:focus,
.ant-input-focused,
.ant-input-number-focused,
.ant-input-affix-wrapper-focused{border-color:#fff; box-shadow:0 0 5px rgba(0,0,0,.1); background-color:#fff; border-left:3px solid #F07E22}

.form-item-block.d-flex .ant-form-item{}

.ant-radio-wrapper .ant-radio{vertical-align:middle;}
.ant-picker{width:100%;}
.ant-input-number{width:100%;}
.ant-form-item .ant-upload.ant-upload-drag{background:#fff;}
.ant-btn-ghost{box-shadow:none; padding:15px 0px;}

.w-18{width:18%;}
.w-35{width:35%;}
.w-40{width:38%;}
.w-50{width:50%;}

.fw-300{font-weight:300;}

.mt-0{margin-top:0px !important;}
.mt-1{margin-top:10px !important;}
.mt-2{margin-top:20px !important;}
.mt-3{margin-top:30px !important;}
.mt-4{margin-top:40px !important;}
.mt-5{margin-top:50px !important;}

.mb-0{margin-top:0px !important;}
.mb-1{margin-top:10px !important;}
.mb-2{margin-top:20px !important;}
.mb-3{margin-top:30px !important;}
.mb-4{margin-top:40px !important;}
.mb-5{margin-top:50px !important;}

.ml-5{margin-left:5px}
.ml-10{margin-left:10px}
.ml-15{margin-left:15px}
.ml-20{margin-left:20px}
.ml-25{margin-left:25px}

.mr-5{margin-right:5px}
.mr-10{margin-right:10px}
.mr-15{margin-right:15px}
.mr-20{margin-right:20px}
.mr-25{margin-right:25px}
.mr-50{margin-right:50px}

.feather{margin-right:5px; vertical-align:middle; width:20px;}


/*      Loader Css Start        */
.wd-loader-block{position:absolute; width:100%; height:100%; display:flex; top:0; left:0; z-index:999; background-color:rgba(255,255,255,.1); justify-content:center; align-items:flex-start;}
.wd-loader{width:150px; height:150px; margin-top:100px}
.wd-loader-block-sm{height:auto; padding:10px 0}
.wd-loader.wd-loader-sm{width:50px; height:50px;}
/*      Loader Css End        */

/*      Buttons Start        */
.btn-default{background-color:#f07e22; border:0px none; border-radius:4px; color:#fff; font-size:16px; font-weight:500; padding:10px 30px;}

.btn-blue{background-color:#003965; border-radius:2px; color:#fff; font-size:16px; font-weight:500; padding:10px 20px; text-transform:unset;}
.btn-blue-round{background-color:#003965; border:0px none; border-radius:25px; color:#fff; font-size:18px; font-weight:500; padding:8px 30px;}
.btn-blue:hover,
.btn-blue-round:hover{background-color:#044f88; color:#fff;}
.btn.no-border{border:0px none;}

.btn-blank-brown{background-color:#fff; border:1px solid #a24921; color:#a24921;}
.btn-blank-brown:hover{background-color:#a24921; color:#fff;}
.btn-brown{border:1px solid #a24921; background-color:#a24921; color:#fff; padding:10px 25px;}
.btn-brown:hover{background-color:#fff; color:#a24921;}

.btn-white{background-color:#fff; color:#a24921; padding:10px 25px;}
.btn-white:hover{background-color:#a24921; color:#fff;}
/*      Buttons End        */

/*      Icons Start     */
.ico{background-image:url(/static/media/icons.df2ca6ca.png); background-repeat: no-repeat; display:inline-block;}
.ico.facebook{background-position:-13px -8px; height:18px; width:10px;}
.ico.twitter{background-position:-42px -7px; height:18px; width:20px;}
.ico.linkedin{background-position:-78px -6px; height:18px; width:20px;}
.ico.youtube{background-position:-14px -263px; height:18px; width:24px;}

.ico.user{background-position:-109px -40px; height:10px; width:11px;}
.ico.clock{background-position:-221px -87px; height:13px; width:13px;}
.ico.heart{background-position:-109px -40px; height:10px; width:11px;}
.ico.email{background-position:-128px -40px; height:9px; width:12px;}

.ico.shield{background-position:-2px -71px; height:63px; width:63px;}
.ico.camera{background-position:-70px -71px; height:63px; width:63px;}
.ico.hand{background-position:-142px -71px; height:63px; width:63px;}
.ico.blue-arrow{background-position:-110px -10px; height:16px; width:28px;}

.ico.share-research{background-position:-8px -151px; height:93px; width:89px;}
.ico.track-change{background-position:-104px -152px; height:85px; width:97px;}
.ico.mining-traffic{background-position:-208px -152px; height:90px; width:87px;}

.ico.sold{background-position:-347px -2px; height:30px; width:32px;}
.ico.offer-green-check{background-position:-146px -39px; height:17px; width:17px;}

.ico.sold-fill{background-position:-242px -86px; height:18px; width:20px;}
.ico.clock-blue{background-position:-267px -110px; height:13px; width:13px;}
.ico.eye-blue{background-position:-242px -108px; height:14px; width:20px;}
.ico.heart-blue{background-position:-221px -110px; height:14px; width:14px;}

.ico.jpg-file{background-position:-340px -87px; height:21px; width:19px;}
.ico.word-file{background-position:-309px -87px; height:21px; width:22px;}
.ico.png-file{background-position:-366px -87px; height:21px; width:20px;}

.ico.clock-grey{background-position:-267px -110px; height:13px; width:13px;}
/*      Icons End     */

/*      Margin & Padding Start      */
.pb-0{padding-bottom:0px !important;}
.pb-1{padding-bottom:10px !important;}
.pb-2{padding-bottom:20px !important;}
.pb-3{padding-bottom:30px !important;}
.pb-4{padding-bottom:40px !important;}
.pb-5{padding-bottom:50px !important;}

.pt-0{padding-top:0px !important;}
.pt-1{padding-top:10px !important;}
.pt-2{padding-top:20px !important;}
.pt-3{padding-top:30px !important;}
.pt-4{padding-top:40px !important;}
.pt-5{padding-top:50px !important;}

.mb-0{margin-bottom:0px !important;}
.mb-1{margin-bottom:10px !important;}
.mb-2{margin-bottom:20px !important;}
.mb-3{margin-bottom:30px !important;}
.mb-4{margin-bottom:40px !important;}
.mb-5{margin-bottom:50px !important;}

.mt-0{margin-top:0px !important;}
.mt-1{margin-top:10px !important;}
.mt-2{margin-top:20px !important;}
.mt-3{margin-top:30px !important;}
.mt-4{margin-top:40px !important;}
.mt-5{margin-top:50px !important;}
/*      Margin & Padding End      */

.hide{display:none;}
ul{margin-bottom:0px; padding-left:0px; list-style-type:none;}
ul[type=disc]{list-style-type:disc; padding-left:20px;}
.pointer{cursor:pointer;}

.small-heading{color:#962c08; font-size:22px;}
.heading{color:#003965; font-size:35px; font-weight:500; margin-bottom:10px;}
.bg-white,
.white-bg{background-color:#fff;}
.inner-page{padding:45px 0;}
.inner-page .heading{color:#003965; font-size:35px; line-height:1.2;}
.inner-page .small-heading{color:#6E939C; font-size:25px; line-height:1.2;}

body{color:#4e413b; margin:0; padding:0; font-family:'Jost', sans-serif, tahoma, arial; font-size:14px; overflow-x:hidden;}
.ant-layout{background-color:#fafafa;}
.blue-bg{background-color:#7303c1; color:#fff;}
.pink-bg{background-color:#ec38bd; color:#fff;}
.mat-ico{color:#003965; font-family:'Material Icons'; font-weight:normal; font-style:normal; font-size:24px; display:inline-block; line-height:1; text-transform:none; letter-spacing:normal; word-wrap:normal; white-space:nowrap; direction:ltr; -webkit-font-smoothing:antialiased; text-rendering:optimizeLegibility; -moz-osx-font-smoothing:grayscale; font-feature-settings:'liga';}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{height:43px; border:1px solid #d9d9d9}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder{line-height:43px}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{height:43px}

.ant-picker,
input.ant-input-number-input{height:43px; border:1px solid #d9d9d9; background-color:#f8f8f8;}

.ant-form-item-control-input{min-height:43px;}
.ant-select:not(.ant-select-customize-input) .ant-select-selector{min-height:43px;}

.pr-15{padding-right:15px;}

.block-heading{border-left:3px solid #A24921; background-color:rgba(162, 73, 33, .1); padding:10px 35px 10px 35px; margin-left:-35px; margin-right:-35px; margin-bottom:25px}
.block-heading span{color:#A24921}

.section-heading{color:#003965; margin-bottom:20px; font-size:20px}

.custom-form-block{padding-bottom:30px; margin-bottom:80px;}
.custom-form-block input,
.custom-form-block select{color:#333; padding:5px 10px;}

.custom-form-block .buttons .btn{margin-left:20px;}

.login-main-page{background-image:url(/static/media/login-bg.84a2300c.png); padding-top:0; background-position:-200px -50px; background-repeat:no-repeat; background-color:#fff; min-height:calc(100vh - 100px)}
.signup-main-page{background-image:url(/static/media/signup-bg.5f6acb49.png); padding-top:0; background-position:-250px -50px; background-repeat:no-repeat; background-color:#fff;}

.login-main-page .auth-block{margin-right:50px; margin-bottom:50px}
.signup-main-page .auth-block{width:50%}
.auth-block{width:45%; margin-top:60px; margin-bottom:60px}
.auth-block .section-heading{font-size:25px;}

.auth-block .ant-input{padding:10px 11px}
.auth-block .ant-input-affix-wrapper{padding:10px 11px}
.auth-block .ant-input-number-input{height:42px;}

.ant-carousel{width:100%;}
.ant-carousel .slick-slide img{width:100%}

.custom-carousel .slick-slide{margin:0px auto; width:calc(100% - 40px);}
.custom-carousel .slick-slide > div{padding:10px;}
.custom-carousel .block{border-radius:8px; box-shadow:0 0 12px #ededed; margin:0px; padding:25px 25px 25px;}
.custom-carousel .block .icon-block{width:50px; position:relative}
.custom-carousel .block .icon-block span{position:absolute; top:22px; left:calc(50% - 5px); width:100%; color:rgba(255,255,255,.8); font-weight:bold}
.custom-carousel .block .text-block{flex:1 1}
.custom-carousel .block .sl-buttons{display:flex; justify-content:space-between; align-items:flex-start;}
.custom-carousel .block .sl-buttons .pointer{align-self:flex-start; font-size:12px; color:#003965; margin-right:10px; position:relative;}
.custom-carousel .block .sl-buttons .pointer:last-child{margin-right:0; margin-left:10px}
.custom-carousel .block .sl-buttons .pointer:first-child:after{content:''; display:block; width:1px; height:12px; background-color:#003965; position:absolute; right:-10px; top:3px;}
.custom-carousel .block{color:#787878; font-size:16px;}
.custom-carousel .block .sl-title{color:#003965; font-weight:600;}
.custom-carousel .block .sl-company-name{margin-bottom:10px; font-size:14px; color:#6E939C}
.custom-carousel .block .sl-from-to{color:#003965; font-size:14px}
.custom-carousel .block .sl-text{color:#6E939C; font-size:14px}
.custom-carousel .block .sl-tags{margin-top:10px;}
.custom-carousel .block .sl-tags .heading{color:#003965; margin-right:10px; font-size:14px;}
.custom-carousel .block .sl-tags .sl-single-tag{color:#6E939C; display:inline-block; font-size:14px; margin-right:15px; border:0 none; padding:0}
.sl-single-tag{border:1px solid #ee7d22; color:#ee7d22; border-radius:16px; display:inline-block; font-size:12px; margin-right:15px; padding:5px 15px;}

.custom-carousel .ant-carousel .slick-dots{bottom:40px;}
.custom-carousel .ant-carousel .slick-dots li{width:16px;}
.custom-carousel .ant-carousel .slick-dots li button{background:#c9c9c9; opacity:1; border-radius:50%; height:6px; width:6px;}
.custom-carousel .ant-carousel .slick-dots li.slick-active button{background-color:#414141;}

.row-portfolio-slider{margin-bottom:30px}
.row-portfolio-slider .block{display:flex; justify-content:space-between;}
.row-portfolio-slider .block .img-block{width:40%; margin-right:30px;}
.row-portfolio-slider .block .sl-title{margin-bottom:10px;}
.update-profile-page .ant-tabs-ink-bar{display:none !important;}

.custom-carousel .row-portfolio-slider .sl-tags .sl-single-tag{border:1px solid #A24921; padding:5px 10px; color:#A24921; border-radius:5px}

.row-portfolio-slider .block .img-block .slick-dots-bottom{bottom:0}
.slider-inner .ant-row{justify-content:space-between}
.slider-inner .ant-row .ant-col-12{max-width:48%; margin-bottom:30px}

.portfolio-main-image-box{flex:1 1; height:120px; background-color:rgba(0,0,0,.01); border:1px solid rgba(0,0,0,.1); justify-content:center; align-items:center; display:flex; cursor:pointer;}
.portfolio-main-image-box .feather{font-size:50px; opacity:.6; width:auto}
.portfolio-image-preview{width:100%; height:100%; background-position:center; background-repeat:no-repeat; background-size:contain;}

.portfolio-image{width:100%; height:200px; background-color:rgba(0,0,0,.01); border:1px solid rgba(0,0,0,.1); background-size:contain; background-position:center; background-repeat:no-repeat;}

.post-project-steps{background-color:#fff; border-radius:50px}
.ant-steps.post-project-steps:not(.ant-steps-dot):not(.ant-steps-navigation):not(.ant-steps-vertical) .ant-steps-item{padding-top:0; width:33.3%}
.post-project-steps .ant-steps-item-title::after{display:none}
.post-project-steps .ant-steps-item-icon{display:none}

.post-project-steps .ant-steps-item .ant-steps-item-container{border-radius:35px; color:#fff; padding:15px; display:flex; justify-content:center; align-items:center;}
.post-project-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{color:rgba(0, 0, 0, 0.85); font-weight:bold}
.post-project-steps.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item{padding-left:0}

.post-project-steps .ant-steps-item.ant-steps-item-finish .ant-steps-item-container{background-color:#003965; border-radius:0; color:#fff; padding:15px; display:flex; justify-content:center; align-items:center;}
.post-project-steps .ant-steps-item-finish:first-child .ant-steps-item-container{border-radius:35px 0 0 35px; border-right:1px solid #fff}
.post-project-steps .ant-steps-item-finish:last-child .ant-steps-item-container{border-radius:0 35px 35px 0; border-left:1px solid #fff}
.post-project-steps .ant-steps-item.ant-steps-item-finish .ant-steps-item-title{color:#fff; font-weight:bold;}

.extra-item{border:1px solid rgba(0, 0, 0, .1); padding:25px; width:48%}

.form-gallery{position:relative;}
.form-gallery ul{display:flex; justify-content:flex-start; align-items:flex-start; flex-wrap:wrap; padding:0}
.form-gallery ul li{width:100px; height:100px; margin-right:20px; margin-bottom:20px; border:1px solid rgba(0,0,0,.1); padding:5px; align-items:center; justify-content:center; display:flex; cursor:pointer; position:relative;}
.form-gallery ul .preview-image{max-width:100%; max-height:100%;}
.form-gallery ul li .close{cursor:pointer; position:absolute; top:0; right:0; background-color:#ff4500; border:0 none; padding:0; margin:0; color:#fff; border-radius:50%; width:20px; height:20px; display:flex; justify-content:center; align-items:center; opacity:0; transition:.2s all ease-in-out;}
.form-gallery ul li .close .feather{margin:0}
.form-gallery ul li:hover .close{opacity:1;}
.form-gallery.lg ul li{width:200px; height:200px}

.file-gallery-preview{}
.file-gallery-preview .file-icon{font-size:50px; color:#7303c1}

.form-gallery-container{display:flex; justify-content:flex-start; align-items:center; margin-bottom:40px;}
.gallery-increment{display:flex; justify-content:center; align-items:center; cursor:pointer; width:100px; height:100px; flex-direction:column;}
.gallery-increment p{margin:0; text-transform:none}
.gallery-increment .feather{margin:0}

.extras-payment-block{border:1px solid rgba(0, 0, 0, .1); padding:25px}

.post-project-extras-page-2 .main-content{justify-content:space-between;}
.post-project-extras-page-2 .main-content .left-block{width:calc(100% - 430px);}
.post-project-extras-page-2 .left-block .payment-blocks{display:flex; flex-wrap:wrap; justify-content:flex-start; margin-bottom:10px; margin-top:40px;}
.post-project-extras-page-2 .left-block .payment-blocks .border-block,
.product-view .left-block .payment-blocks .border-block{background-color:#fff; border:1px solid #e3e3e3; padding:22px 30px; margin-right:5%;  width:30%;}
.post-project-extras-page-2 .left-block .payment-blocks .border-block:nth-child(3n),
.product-view .left-block .payment-blocks .border-block:nth-child(3n){margin-right:0px;}
.post-project-extras-page-2 .form-item-block.border-block .ant-checkbox{margin:0px;}
.post-project-extras-page-2 .ant-checkbox-wrapper,
.product-view .ant-checkbox-wrapper{font-size:18px; text-transform:none; text-transform:initial;}
.post-project-extras-page-2 .link{color:#f07e22; font-size:inherit; font-weight:inherit; margin-bottom:0px;}
.post-project-extras-page-2 .agree-block{margin-bottom:20px;}
.post-project-extras-page-2 .form-end > div{display:flex;}
.post-project-extras-page-2 .form-end > div svg{width:20px;}
.post-project-extras-page-2 .form-end > div .text{margin-left:10px;}
.post-project-extras-page-2 .form-end > div .text .top{font-size:18px; color:#414141; text-transform:uppercase;}
.post-project-extras-page-2 .form-end > div .text .bottom{font-size:15px; color:#b8b4b3;}
.post-project-extras-page-2 .main-content .right-block{border-radius:5px; background-color:#fff; padding:45px; width:400px;}
.post-project-extras-page-2 .right-block .d-flex,
.product-view .right-block .d-flex{justify-content:space-between;}
.post-project-extras-page-2 .right-block .d-flex + .d-flex,
.product-view .right-block .d-flex + .d-flex{margin-top:15px;}
.post-project-extras-page-2 .right-block .text{font-size:16px}
.post-project-extras-page-2 .right-block .text.bold,
.product-view .right-block .text.bold{color:#404040; font-size:18px; font-weight:600;}
.post-project-extras-page-2 .right-block .num,
.product-view .right-block .num{font-size:18px;}
.product-view .summary-block{border-top:1px solid #ddd; margin-top:10px; padding-top:10px;}


/*      Header Start    */
.ant-layout-header{background-color:#fff; border-bottom:1px solid #ccc; display:flex; flex-direction:column; height:98px; line-height:normal; line-height:initial; justify-content:center; padding:0}
.header-block{display:flex; justify-content:space-between; position:relative;}
.header-block .first-block{display:flex;}
.header-block .second-block{align-self:flex-end;}
.header-block .third-block{display:flex; flex-direction:column; justify-content:center;}
.navbar-form span:first-child{color:#828282;}
.navigation{align-self:center; margin-right:40px; margin-top:16px; width:24px;}
.navigation .nav-lines{cursor:pointer; display:flex; flex-direction:column; height:16px; justify-content:space-between;}
.navigation .line{background-color:#4e413b; height:3px; width:100%;}
.mega-menu{display:none; padding-top:33px; position:absolute; width:100%; z-index:9999;}
.navigation:hover .mega-menu{display:block;}
.mega-menu .bg-white{width:100%;}
/* .ant-menu .ant-menu-submenu-arrow{display:none;}
.bg-white > .ant-menu{background-color:#4e413b; padding-top:20px; width:25%;}
.bg-white > .ant-menu .ant-menu-submenu-title{color:#4e413b; height:auto; line-height:1; padding:15px 40px;}
.bg-white > .ant-menu > li > .ant-menu-submenu-title{color:#fff;}
.bg-white > .ant-menu > li > .ant-menu-submenu-title:hover{background-color:#f8f8f8; color:#4e413b;} */

.mega-menu .menu{background-color:#4e413b; padding-top:20px; position:relative; width:25%;}
.nav-link{padding:0px;}
.mega-menu .menu a{color:#4e413b; display:block; padding:15px 40px; text-decoration:none; width:100%;}
.mega-menu .menu .sub-menu{background-color:#eaeaea; display:flex; padding:20px; flex-wrap:wrap; opacity:0; transition:.2s all ease-in-out;}
.mega-menu .menu .sub-menu .sub-menu-2{background-color:#fff; display:none; left:100%; padding-top:20px; position:absolute; top:0px; width:100%;}
.mega-menu .menu > li.nav-link > a{color:#f3f6f9;}
.mega-menu .menu > li.nav-link > a:hover{background-color:#f8f8f8; color:inherit;}
.mega-menu .menu > li:hover .sub-menu{opacity:1;}
.mega-menu .sub-menu > li > a:hover{background-color:#fff;}

.sub-menu-wrapper{background-color: #eaeaea;height:100%; width:calc(1240px - 310px); position:absolute; left:100%; top:0; display:none}
.mega-menu .menu > li:hover .sub-menu-wrapper{display:block}

.mega-menu .menu .sub-menu .nav-link{width:30%}

.mega-menu .sub-menu > li:hover .sub-menu-2{display:block;}
.mega-menu .sub-menu-2 > li > a:hover{background-color:#f8f8f8;}

header .links{display:flex;}
header .links a,
header .links .btn{border:0px none; background-color:transparent; color:#707070; font-size:16px; padding:12px 20px;}
header .links .btn{margin-left:15px;}
header .links .btn-fill{background-color:#962c08; color:#fff; margin:0; display:block}
header .ant-layout-header .ant-menu{border:0px none;}

header .ant-menu{border-bottom:0px none;}
header .ant-menu > li{border:0px none !important; margin-left:15px !important; margin-right:0px !important;}
header .ant-menu-submenu > .ant-menu-submenu-title{color:#707070; font-size:16px; padding:7px 20px !important;}
header .ant-menu-submenu:hover:after,
header .ant-menu-submenu:after{border:0 none !important}

.navbar-form{box-shadow:0 0 5px #eee; display:flex; justify-content:center; margin-bottom:0px;}
.navbar-form .form-control{margin-bottom:0px;}
.navbar-form span{align-self:center;}
.navbar-form input{border:0px none; border-radius:0px; border-right:1px solid #cfcfcf; color:#828282; font-size:13px; font-weight:300; max-width:140px; padding:10px 25px;}
.navbar-form span.block:nth-child(2){background-color:#f8f8f8; padding:4px 0;}
.navbar-form span:nth-child(2) .form-control{color:#3e3e3e; font-size:13px; font-weight:300; padding:0px;}
.navbar-form span:nth-child(2) .form-control,
.navbar-form span:nth-child(2) .form-control:active,
.navbar-form span:nth-child(2) .form-control:focus{border:0px none;}
.navbar-form span:nth-child(2) .form-control .ant-select-selector{border:0px none; font-size:16px;}
.navbar-form button.icon{background-color:#ee7d22; border:0px none; display:flex; flex-direction:column; justify-content:center; text-align:center; padding:5px 10px; border-radius:0 5px 5px 0; cursor:pointer}
.navbar-form button.icon .mat-ico{color:#fff; font-size:16px;}
/*      Header End      */


/*      Footer Start    */
.ant-layout-footer{background-color:#4e413b; padding:0px;}
.ant-layout-footer .top-block{padding:70px 0 50px;}
.ant-layout-footer .input-box input{background-color:#e9eaeb; border:0px solid; border-radius:44px; height:45px; padding:0px 25px; width:730px;}
.ant-layout-footer .footer-block{display:flex; flex-wrap:wrap; justify-content:space-between;}
.ant-layout-footer .footer-block .block{padding:0px 15px; width:23%;}
.ant-layout-footer .footer-block .block:first-child{padding-left:0px;}
.ant-layout-footer .footer-block .block:last-child{padding-right:0px;}
.ant-layout-footer .block .heading{font-size:20px; color:#fff; margin:15px 0px;}

.ant-layout-footer .block ul li{margin-bottom:6px;}
.ant-layout-footer .block ul li a{color:#fff; display:block; font-size:14px; padding:5px 0;}

.ant-layout-footer .copyright{border-top:1px solid #828282; color:#828282; font-size:16px; font-family:Nunito; margin-top:20px; padding:35px 0;}
.ant-layout-footer .copyright .block{justify-content:space-between;}
.ant-layout-footer .copyright .left{align-items:center; display:flex;}
.ant-layout-footer .copyright ul{justify-content:space-between; margin-bottom:0px;}
.ant-layout-footer .copyright ul li{margin-bottom:0px;}
.ant-layout-footer .copyright ul li:not(:first-child){margin-left:15px;}
.ant-layout-footer .copyright ul li a{align-items:center; border-radius:50%; border:1px solid #828282; display:flex; flex-direction:column; height:36px; justify-content:center; padding:0px; text-align:center; width:36px;}
.ant-layout-footer .copyright ul li a:hover{border-color:#f07e22;}
.ant-layout-footer .copyright ul li a:hover .ico.facebook{background-position:-13px -38px; height:18px; width:10px;}
.ant-layout-footer .copyright ul li a:hover .ico.twitter{background-position:-42px -37px; height:18px; width:20px;}
.ant-layout-footer .copyright ul li a:hover .ico.linkedin{background-position:-78px -37px; height:18px; width:20px;}
.ant-layout-footer .copyright ul li a:hover .ico.youtube{background-position:-14px -288px; height:18px; width:24px;}
/*    Footer End    */


/*      Homepage Start      */
.homepage .ant-layout{background-color:#fff;}

/*      Homepage Carousel Start     */
.ant-carousel .slick-arrow{background-color:transparent; border:0px none; height:40px; font-size:0px; padding:0px; position:absolute; text-align:center; top:45%; width:40px;}
.ant-carousel .slick-prev{left:-40px;}
.ant-carousel .slick-next{right:-40px;}
.ant-carousel .slick-arrow::before{background-image:url(/static/media/icons.df2ca6ca.png); background-repeat: no-repeat; display:inline-block; }
.ant-carousel .slick-arrow.slick-prev::before{background-position:-159px -8px; height:22px; width:13px;}
.ant-carousel .slick-arrow.slick-next::before{background-position:-192px -8px; height:22px; width:13px;}
.trusted-partners-sectio .ant-tabs,
.sp-projects-block .ant-tabs{overflow:revert;}

.banner-slider .banners-block{background-repeat:no-repeat; background-position:center center; color:#fff; min-height:300px; width:100%;}
.banner-slider .bg-block{height:100%; width:100%;}
.banner-slider .block{max-width:700px; position:absolute; top:110px;}
.banner-slider .head-text{font-size:64px; font-weight:700; line-height:1.2;}
.banner-slider .description{font-size:18px; margin-bottom:35px;}
.banner-slider .links a{font-size:16px; margin-right:12px;}
/*      Homepage Carousel Start     */

/*      Home Trusted Partners Block Start     */
.trusted-partners-section{padding:30px 0;}
.trusted-partners-section .container{display:flex; justify-content:space-between;}
.trusted-partners-section .left-block{width:230px;}
.trusted-partners-section .heading{line-height:1.2;}
.trusted-partners-section .right-block{width:calc(100% - 250px);}

.trusted-partners-listing .single{align-items:center; display:flex !important; flex-direction:column; height:auto; justify-content:center;}
.trusted-partners-listing .slick-arrow{top:40%;}
/*      Home Trusted Partners Block End     */

/*      Home Categories Block Start        */
.categories-section{padding:45px 0 130px;}
.categories-section .heading{margin-bottom:50px;}
.categories-section .categories-list{display:flex; flex-wrap:wrap; justify-content:flex-start; width:100%;}
.categories-section .categories-list .category-block{border:1px solid #eaeaea; border-radius:1px; color:#003965; display:block; font-size:20px; margin-bottom:20px;overflow:hidden; position:relative; text-decoration:none; width:23%; margin-right:2%;}
.categories-section .categories-list .category-block:nth-child(4n){margin-right:0}
.categories-section .categories-list .category-block:first-child{margin-right:2%}

.categories-section .categories-list .category-block .main{padding:55px 20px 80px; min-height:320px;}
.categories-section .categories-list .category-block .main img{margin:0 auto; width:100px}
.categories-section .categories-list .category-block .hover-div{background-color:#928a87; color:#fff; font-size:16px; height:100%; padding:50px 20px; position:absolute; top:100%; transition:all 0.3s ease-in-out 0s; width:100%; z-index:99;}
.categories-section .categories-list .category-block .hover-div .title{font-size:20px;}
.categories-section .categories-list .category-block .hover-div .explore-btn{display:flex; justify-content:center; margin-top:15px;}
.categories-section .categories-list .category-block .mat-ico{align-self:center; margin-top:2px; transform:rotate(180deg);}
.categories-section .categories-list .category-block:hover .hover-div{top:0;}
.categories-section a.load-more{margin-top:30px;}
/*      Home Categories Block End        */

/*      Home Image Text Block Start        */
.img-text-section{display:flex;}
.img-text-section > div{width:50%;}
.img-text-section > div.img-block{display:flex; justify-content:flex-end;}
.img-text-section.rev-row > div.img-block{display:flex; justify-content:flex-start;}
.img-text-section .text-block .block{padding:85px 90px;}
.img-text-section .text-block .text{color:#003965; margin-bottom:50px;}
.img-text-section .current-block-categories{margin-top:55px;}
.img-text-section .current-block-categories .title{font-size:20px; font-weight:500; margin-bottom:15px;}
.img-text-section .categories-list .btn-blank-brown{margin-bottom:8px; margin-right:10px;}

.img-text-section .categories-list{display:flex; flex-wrap:wrap;}
.img-text-section .categories-list a{margin-bottom:5px; margin-left:5px;}

.img-text-section.discover-sp .text-block{border-top:1px solid #f0f0f0;}
.img-text-section.explore-offers .text-block{border-bottom:1px solid #f0f0f0;}
/*      Home Image Text Block End        */

/*      Home SP Project Tabs Start      */
.sp-projects{padding-bottom:60px; padding-top:105px;}
.sp-projects-block{display:flex; flex-direction:column;}
.sp-projects-block .nav-tabs{align-self:center; border-color:#828282; margin-bottom:60px;}
.sp-projects-block .nav-tabs .nav-item{}
.sp-projects-block .ant-tabs-nav .ant-tabs-tab{color:#003965; font-size:25px; margin-right:0px; padding:12px 25px; position:relative;}
.sp-projects-block .ant-tabs-nav .ant-tabs-tab-active,
.sp-projects-block .ant-tabs-nav .ant-tabs-tab:hover,
.sp-projects-block .ant-tabs-nav .ant-tabs-tab:active,
.sp-projects-block .ant-tabs-nav .ant-tabs-tab:focus{border:0px none; color:#962c08;}
.sp-projects-block .ant-tabs-nav .ant-tabs-tab::after{background-color:#828282; bottom:1px; content:''; display:block; height:1px; left:0px; position:absolute; width:100%;}
.sp-projects-block .ant-tabs-nav .ant-tabs-tab-active::after,
.sp-projects-block .ant-tabs-nav .ant-tabs-tab:hover::after{background-color:#962c08; height:4px;}
.sp-projects-block .ant-tabs-tab .ant-tabs-tab-btn{color:#962c08; margin:0 auto;}

.sp-projects .btn.btn-fill{background-color:#962c08; color:#fff; display:inline-block; font-size:20px; font-weight:500; margin-top:20px; padding:8px 20px;}

.projects-listing{}
.projects-listing .slick-slide > div{margin:0 15px;}
.projects-listing .single-project{border:1px solid #bebebe; border-radius:5px; color:#003965; height:auto;}
.projects-listing .single-project:hover{border-color:#f07e22; box-shadow:0 0 20px #eee;}
.projects-listing .single-project .top-block{border-bottom:1px solid #c7c7c7; padding:40px;}
.projects-listing .title{font-size:20px; font-weight:500; margin-bottom:8px;}
.projects-listing .added-on{font-weight:500; margin-bottom:14px;}
.projects-listing .tags{display:flex; flex-wrap:wrap; justify-content:flex-start; margin-bottom:12px;}
.projects-listing .tag{background-color:#962c08; border-radius:5px; color:#fff; font-size:15px; font-weight:500; padding:5px 12px;}
.projects-listing .text{font-size:16px; margin-bottom:28px; opacity:0.5;}
.projects-listing .links{font-size:13px; font-weight:500;}
.projects-listing .links > span{margin-right:25px;}
.projects-listing .links .ico{margin-right:5px;}
.projects-listing .single-project .bottom-block{display:flex; justify-content:space-between; padding:20px 40px;}
.projects-listing .single-project .price-block{align-self:center; font-size:24px;}
.sp-projects-block .row-item .dark-blue-btn{background-color:#003965; border-radius:4px; color:#fff; font-size:14px; font-weight:500; padding:4px 12px; align-self:flex-start; min-width:100px;}

.sp-projects-block .ant-tabs-bar{border-bottom:0px none; margin-bottom:60px;}
.sp-projects-block .ant-tabs-top .ant-tabs-ink-bar-animated{display:none !important;}
.sp-projects-block .ant-tabs-nav-scroll{text-align:center;}
.sp-projects-block .ant-tabs-nav-scroll > div{margin:0 auto;}

.sp-list-block{border-bottom:15px solid #e7e6e6; display:flex; justify-content:center; margin-top:115px; padding-bottom:35px;}
.sp-list-block > div{color:#003965; display:flex; flex-direction:column; justify-content:center; text-align:center;  width:33.33%;}
.sp-list-block > div:not(:last-child){border-right:1px solid #d1d1d1;}
.sp-list-block > div:hover{color:#962c08;}
.sp-list-block .num{font-size:35px; font-weight:500;}
.sp-list-block .text{font-size:16px;}
/*      Home SP Project Tabs End      */

/*      Home How We Work Start      */
.how-we-work{padding-top:55px;}
.how-we-work-block{display:flex; justify-content:space-between; padding:50px 0;}
.how-we-work-block .heading{margin-bottom:0px;}
.how-we-work-block > .left{width:40%;}
.how-we-work-block .left .text{line-height:1.8; width:70%;}
.how-we-work-block .bottom-block{align-items:flex-start; display:flex; justify-content:space-between; margin-top:20px;}
.how-we-work-block .bottom-block .btn-default{margin-top:20px;}
.how-we-work-block > .right{width:50%;}
.how-we-work-block .blocks{display:flex; justify-content:space-between; position:relative;}
.how-we-work-block .blocks .left,
.how-we-work-block .blocks .right{width:256px;}
.how-we-work-block .block{box-shadow:0 0 20px #eee; padding:35px; width:100%;}
.how-we-work-block .block:hover{box-shadow:0 0 8px #eee;}
.how-we-work-block .blocks .block:not(:first-child){margin-top:55px;}
.how-we-work-block .blocks .right .block:first-child{margin-top:75px;}
.how-we-work-block .img-block{}
.how-we-work-block .block-title{color:#003965; font-size:20px; font-weight:500;}
/*      Home How We Work End        */

/*      Home Money Protection Block Start        */
.money-protection-block{padding-bottom:125px; padding-top:30px;}
.money-protection-block .small-heading{text-transform:uppercase;}
.money-protection-block > .text{color:#003965; margin:0 auto; max-width:700px;}
.money-protection-block .circle-blocks::before{background-color:#d6d6d6; content:''; display:inline-block; height:2px; position:absolute; top:21px; width:100%;}
.money-protection-block .circle-blocks{display:flex; justify-content:space-between; margin:60px auto; position:relative; width:70%;}
.money-protection-block .blocks{display:flex; justify-content:space-between; margin-top:60px;}
.money-protection-block .block{color:#003965; padding:50px; width:30%;}
.money-protection-block .block:hover{box-shadow:0 0 30px #eee;}
.money-protection-block .circle-blocks .block{background-color:#fff; box-shadow:0 0 10px #eee; border-radius:50%; display:flex; flex-direction:column; font-size:20px; font-weight:500; height:42px; justify-content:center; padding:0px; width:42px; z-index:1;}
.money-protection-block .circle-blocks .block:hover{background-color:#003965; color:#fff;}
.money-protection-block .img-block{margin-bottom:40px;}
.money-protection-block .block-title{font-size:20px; font-weight:500; margin-bottom:8px;}
.money-protection-block .block-text{display:block;}
.money-protection-block .read-more{display:inline-block; margin-top:42px;}
/*      Home Money Protection Block End        */
/*      Homepage End      */


/*      Listing Page Start     */
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector{padding:0 20px;}
.ant-select-single.ant-select-open:not(.ant-select-customize-input) .ant-select-selector{border-color:#000;}

.orange-bg-img{background-image:url(/static/media/projects-bg.eb2bc835.jpg) no-repeat scroll center center; background-size:cover; min-height:136px;}
.banner-block{display:flex; flex-direction:column; justify-content:center; margin-bottom:50px; min-height:136px; position:relative;}
.banner-block > img{height:100%; position:absolute; z-index:1;}
.banner-block .container{color:#fff; line-height:1.2; position:relative; z-index:2;}
.banner-block .container .heading{color:#fff; font-weight:bold; margin:0px;}
.banner-block .container .text{font-size:20px;}

.ant-tabs-tab:hover{color:#003965;}

.search-row{margin-bottom:50px !important;}
.search-row .ant-select-selector{border:0px none !important;}
.search-row .ant-input-search-button{background-color:#F07E22; border:0px none; border-radius:50% !important; height:40px; margin-left:-20px; padding:0px; width:40px; z-index:1;}
.listing-section.white-bg,
.right-section.white-bg{padding:30px 20px;}
.search-count{color:#003965; font-size:25px; font-weight:600;}
.projects-search .ant-select-item{font-size:16px; padding:0 10px;}
.ant-select-item .ant-select-item-option-content{border-radius:5px; color:#000; padding:5px;}

.ant-select-item,
.ant-select-item-option-active,
.ant-select-item-option-selected,
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){background-color:transparent;}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) .ant-select-item-option-content,
.ant-select-item-option-selected .ant-select-item-option-content{background-color:rgba(0, 0, 0, .1);}

.listing-section .listing{margin:0 0 50px;}
.listing-section .top-filters{margin-bottom:20px;}
.listing-section .single-item{border:1px solid #B5B5C3; padding:20px;}
.listing-section .single-item:not(:last-child){margin-bottom:20px;}
.listing-section .single-item:hover{box-shadow:0 0 15px #eee;}
.listing-section .single-item .left{margin-right:25px}
.listing-section .single-item .top-section{color:#003965;}
.listing-section .single-item .title{font-size:20px; font-weight:600; max-width:70%;}
.listing-section .single-item .price{font-size:24px; font-weight:bold; line-height:1.4;}
.listing-section .single-item .type{font-size:10.5px; text-align:right;}
.listing-section .single-item .mid-section{max-width:70%;}
.listing-section .single-item .mid-section .tags{margin-bottom:15px;}
.listing-section .single-item .mid-section .tags .ant-tag{background-color:#962C08; border:0px none; border-radius:5px; color:#fff; font-size:15px; font-weight:500; padding:5px 10px;}
.listing-section .single-item .mid-section .description{color:#6E939C;}
.listing-section .single-item .bottom-section{display:flex; justify-content:space-between;}
.listing-section .bottom-section .details{align-self:flex-end; display:flex; justify-content:space-between; width:70%;}
.listing-section .bottom-section .details .single{display:flex; width:24%;}
.listing-section .bottom-section .details .anticon{align-self:center; color:#F07E22; margin-right:5px;}
.listing-section .bottom-section .details .text{color:#003965; font-weight:600;}
.listing-section .bottom-section .dark-blue-btn{background-color:#003965; border-radius:4px; color:#fff; font-size:16px; font-weight:500; padding:8px 20px;}
.listing-section .bottom-section .dark-blue-btn:hover{background-color:#1b517a;}

.listing-section .pagination-block{display:flex; justify-content:space-between; margin-top:20px}

.ant-pagination.mini .ant-pagination-item{position:relative;}
.ant-pagination.mini .ant-pagination-item-active{border:0px none;}
.ant-pagination.mini .ant-pagination-item-active::after,
.ant-pagination.mini .ant-pagination-item:hover::after{content:''; display:block; position:absolute; height:1px; width:10px; background-color:#003965; bottom:0px; left:37%;}
.ant-pagination.mini a{color:#6E939C; font-size:19px; font-weight:500; padding:0 10px;}
.ant-pagination.mini .ant-pagination-prev a,
.ant-pagination.mini .ant-pagination-next a{font-size:15px;}
.ant-pagination.mini .ant-pagination-item-active a,
.ant-pagination.mini .ant-pagination-item:hover a{color:#003965;}

.filters .main-title{color:#003965; font-size:20px; font-weight:600; margin-bottom:15px;}
.filters .filter-heading{color:#6E939C; font-size:18px; font-weight:500; margin-bottom:10px; text-transform:uppercase;}
.filters .filter-list{margin-bottom:20px;}
.filters .filter-list .single{display:block; max-height:150px; overflow:auto;}
.filters .filter-list .ant-checkbox-wrapper{color:#003965; font-weight:600; margin-bottom:12px; margin-left:0px; width:100%;}
.filters .filter-list .ant-checkbox + span{display:flex; float:right; justify-content: space-between; width:calc(100% - 20px);}
.filters .filter-list .ant-checkbox-wrapper .num{color:#6E939C;}
.filters .filter-list .dull{color:#6E939C; font-weight:500; margin-right:8px;}
.filters .filter-list .colored{color:#003965; font-weight:500; margin-left:8px;}
.ant-switch-small{height:18px; min-width:40px;}
.ant-switch-small::after{height:14px; width:14px;}
.ant-switch-checked{background-color:#003965;}

.ant-divider-horizontal.ant-divider-with-text{color:#003965; font-weight:600; font-size:18px;}
.ant-slider-handle{border:1px solid #707070; height:16px; margin-top:-6px; width:16px;}
.ant-slider-track,
.ant-slider:hover .ant-slider-track{background-color:#F07E22;}
.filters .button{display:flex; justify-content:flex-end;}

.hidden{display:none !important}

.profile-pic-box{margin-bottom:30px}
.profile-pic-box .picture{display:flex; justify-content:flex-start; align-items:center;}
.profile-pic-box .picture label{color:#003965; margin-right:50px}
.profile-pic-box .picture .profile-pic-bg{width:50px; height:50px; background-color:#fff; display:flex; justify-content:center; align-items:center; box-shadow:0 2px 5px rgba(0,0,0,.1); border-radius:5px;}
.profile-pic-box .picture .profile-pic-banner-bg{width:100%; height:100px; background-color:#fff; display:flex; justify-content:center; align-items:center; box-shadow:0 2px 5px rgba(0,0,0,.1); border-radius:5px;}

.profile-pic-box .profile-pic-sm{width:100%; height:100%; background-size:100%; background-position:center center; background-repeat:no-repeat}
.profile-pic-box .profile-banner-preview{width:100%; height:100%; background-size:100%; background-position:center}

.profile-pic-box.type-input .picture{flex-direction:column; align-items:flex-start}
.profile-pic-box.type-input .picture > label{margin-bottom:20px}
.profile-pic-box.type-input .picture .profile-pic-banner-bg{align-items:center; justify-content:flex-start; padding-left:35px;}
.profile-pic-box.type-input .picture .profile-pic-banner-bg.solid{background-color:#f8f8f8;}
.profile-pic-box.type-input .picture .profile-pic-banner-bg .feather{width:50px; height:50px; color:rgba(0, 57, 101, .4)}
.profile-pic-box.type-input .picture .profile-pic-banner-bg p{margin:0 0 0 20px; color:#003965; font-size:14px;}

.search-block{width:100%; display:flex; justify-content:center; align-items:center; margin-bottom:10px; margin-top:30px}
.search-block .search-row{width:100%;}
.search-block .ant-select{width:100%;}
.listing-page .ant-select-selector{background-color:#fff !important; font-size:16px; height:56px !important;}
.listing-page .ant-select-selector .ant-select-selection-item,
.listing-page .ant-select-selector .ant-select-selection-placeholder{line-height:54px !important;}
.search-block input{border:1px solid #828282; border-radius:0px; border-top-right-radius:28px; border-bottom-right-radius:28px; font-size:16px; height:56px; padding:18px 64px 18px 18px; width:100%;}
.search-block .search-input{flex:1 1}
.search-block .search-input .ant-select{}
.search-block .search-input input{border:0 none; padding:10px; width:100%;}
.search-block .search-icon{opacity:.4; margin-left:20px; margin-right:20px;}
.search-block .search-button{background-color:#f07e22; border:0 none; padding:12px; border-radius:50%; height:56px; position:absolute; right:0px; width:56px;}
.search-block .search-button .search-btn-icon{margin:0; color:#fff}

.ant-list-pagination{text-align:center;}
/*      Listing Page End     */

/*      Freelancers Listing Page Start      */
.ant-rate{display:flex; justify-content:center;}
.ant-rate-star:not(:last-child){margin-right:2px;}
.ant-rate-star svg{height:16px;}
.ant-rate-star.ant-rate-star-full path{color: #F07E22;}

.freelancers-search .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector{background-color:#F4F4F4; border:0px none;}

.row-item{border-radius:10px; border:1px solid rgba(0, 0, 0, .2); justify-content:space-between; padding:20px; background-color:#fff; display:flex;}
.projects-search .row-item{flex-direction:column;}

.listing-section .top-filters{margin-bottom:20px;}
.row-item{border:1px solid #B5B5C3; padding:20px;}
.row-item:not(:last-child){margin-bottom:20px;}
.row-item:hover{box-shadow:0 0 15px #eee;}
.row-item .top-section{color:#003965;}
.row-item .title{color:#003965; font-size:20px; font-weight:600; max-width:75%;}
.row-item .price{font-size:24px; font-weight:bold; line-height:1.4;}
.row-item .type{font-size:10.5px; text-align:right;}
.row-item .mid-section{max-width:70%;}
.row-item .bottom-section .tags{}
.row-item .bottom-section .tags .ant-tag{background-color:#962C08; border:0px none; border-radius:5px; color:#fff; font-size:15px; font-weight:500; padding:5px 10px;}
.row-item .mid-section .description{color:#6E939C;}
.row-item .bottom-section{display:flex; justify-content:space-between;}
.listing-section .bottom-section .details{align-self:flex-end; display:flex; justify-content:space-between; width:70%;}
.listing-section .bottom-section .details .single{display:flex; width:24%;}
.listing-section .bottom-section .details .anticon{align-self:center; color:#F07E22; margin-right:5px;}
.listing-section .bottom-section .details .text{color:#003965; font-weight:600;}

.row-item .price{font-size:20px;}
.row-item .price .normal{font-weight:300;}
.row-item .location{font-size:15px; margin-bottom:12px;}
.row-item .description{ margin-bottom:10px;}
.row-item .tags .ant-tag{background-color:#928A87; font-size:13px; border:0px; color:#fff; padding:2px 10px;}
.row-item .tags .total-other{color:#FC9E00}

.row-item{border-radius:10px; border-color:#FC9E00; justify-content:space-between; }
.row-item .left{width:132px;}
.row-item .right{width:calc(100% - 152px);}
.row-item .profile-image{position:relative;}
.row-item .profile-image .featured-text{background-color:#962C08; color:#fff; font-size:15px; font-weight:500; position:absolute; left:-20px; top:0px; padding:5px 12px; border-top-right-radius:5px; border-bottom-right-radius:5px; box-shadow:0 0 10px rgba(255,255,255, .16);}
.row-item .rating-text{color:#003965; text-align:center;}

.listing .verified{color:#52C619; font-weight:600; font-size:13px; margin-left:20px;}
.freelancers-search .featured .ant-tag{background-color:#962C08; font-size:15px; border:0px; border-radius:5px; color:#fff; margin-left:20px; padding:5px 15px;}
.row-item .price{font-size:20px;}
.row-item .price .normal{font-weight:300;}
.row-item .location{font-size:15px; margin-bottom:12px;}
.row-item .description{ margin-bottom:10px;}
.row-item .tags.tags .ant-tag{background-color:#962C08; border-radius:5px; font-size:15px; font-weight:500; border:0px; color:#fff; margin-bottom:10px; padding:5px 10px;}
.row-item .tags.tags .total-other{color:#FC9E00}
.row-item .tags.dark-blue-btn{font-size:15px; padding:5px 15px;}
/*      Freelancers Listing Page End      */

/*      Blog Listing Page Start      */
.blank-blue-btn{border:1px solid #003965 !important; border-radius:5px; color:#003965; font-size:16px; height:auto; padding:5px 20px;}
.blank-blue-btn:hover{background-color:#003965; color:#fff}

.blog-listing-section.inner-page .listing-section .heading{color:#003965; font-size:30px; font-weight:600; margin-bottom:40px;}
.blog-listing-section .right-section .text{color:#003965; font-size:20px; font-weight:600;}

.blog-listing-section .social-links-text{margin-bottom:30px;}
.blog-listing-section ul.social-links{justify-content:space-between; margin-bottom:0px;}
.blog-listing-section ul.social-links li{margin-bottom:0px; margin-left:10px;}
.blog-listing-section ul.social-links li a{align-items:center; border-radius:50%; border:1px solid #828282; display:flex; flex-direction:column; height:34px; justify-content:center; padding:0px; text-align:center; width:34px;}
.blog-listing-section ul.social-links li a:hover{border-color:#f07e22;}
.blog-listing-section ul.social-links li a:hover .ico.facebook{background-position:-13px -38px; height:18px; width:10px;}
.blog-listing-section ul.social-links li a:hover .ico.twitter{background-position:-42px -37px; height:18px; width:20px;}
.blog-listing-section ul.social-links li a:hover .ico.linkedin{background-position:-78px -37px; height:18px; width:20px;}

.blog-listing-section .single-item{border-radius:5px; border-color:#DEDEDE; justify-content:space-between; overflow:hidden; padding:0px;}
.blog-listing-section .single-item .left{width:256px;}
.blog-listing-section .single-item .right{padding:20px; width:calc(100% - 256px);}
.blog-listing-section .profile-block{display:flex; margin-bottom:14px;}
.blog-listing-section .profile-block img{border-radius:5px; margin-right:15px; max-height:52px; max-width:53px;}
.blog-listing-section .profile-detail{display:flex; flex-direction:column;}
.blog-listing-section .profile-block .name{color:#003965; font-size:18px; font-weight:600;}
.blog-listing-section .profile-block .category-name{color:#6E939C; font-size:16px;}
.blog-listing-section .single-item .title{color:#003965; margin-bottom:10px; max-width:100%;}
.blog-listing-section .single-item .description{color:#6E939C}
.blog-listing-section .single-item .bottom-section{align-items:center; display:flex; margin-top:20px;}
.blog-listing-section .single-item .comment-block{color:#6E939C; display:flex; font-size:16px; font-weight:500;}
.blog-listing-section .single-item .comment-block .mat-ico{margin-right:5px;}

.blog-listing-section .search-row input{border-color:#828282;}
.blog-listing-section .ant-collapse{background-color:transparent; border-bottom:1px solid; border-color:#EDEDED; border-radius:5px; font-size:16px; font-weight:600; margin-bottom:10px; overflow:hidden;}
.blog-listing-section .ant-collapse-header{background-color:#F8F8F8; color:#003965;}
.blog-listing-section .ant-collapse-item-active .ant-collapse-header{background-color:#A24921; color:#fff;}
.blog-listing-section .ant-collapse > .ant-collapse-item{border-bottom-color:#EDEDED;}
.blog-listing-section .ant-collapse > .ant-collapse-item:last-child{border-bottom-color:transparent;}
.blog-listing-section .ant-checkbox-wrapper{color:#222; font-weight:400; margin-left:0px; width:100%;}
.blog-listing-section .ant-checkbox-wrapper:not(:last-child){margin-bottom:12px;}
.blog-listing-section .ant-checkbox{margin-right:20px;}

.blog-listing-section .other-posts-block{margin-top:40px;}
.other-posts-block .heading{color:#A24921; font-size:20px; margin-bottom:30px;}
.other-posts .single-item{border:1px solid #D3D3D3; border-radius:5px; line-height:1.4; padding:20px 12px;}
.other-posts .single-item:not(:last-child){margin-bottom:20px;}
.other-posts .single-item:hover{box-shadow:0 0 10px rgba(131, 131, 131, .16);}
.other-posts .single-item .title{color:#003965; font-size:16px;}
.other-posts .single-item .desc{color:#6E939C;}
.other-posts .single-item .link{color:#962C08; display:inline-block; font-size:16px; font-weight:500; margin-top:12px;}
/*      Blog Listing Page End      */

.post-project-page form label.upload-panel{background-color:#fff; border:1px dashed rgba(0,0,0,.1); display:flex; justify-content:center; flex-direction:column; align-items:center; padding:30px; cursor:pointer; transition:.2s all ease-in-out}
.post-project-page form label.upload-panel:hover{background-color:rgba(115, 3, 192, .03); border-color:rgba(115, 3, 192, .3)}
.post-project-page form label.upload-panel .icon{font-size:50px; color:#ee7d22}
.post-project-page form label.upload-panel p{margin:0; font-weight:normal; text-transform:capitalize; color:rgba(0,0,0,.5);}


/*      Prices Page Start      */
.prices-page .banner-block{margin-bottom:0px;}
/* .prices-top-section{background:linear-gradient(to bottom right, #fff 0%, #fff 50%, #FBFBFB 50%, #FBFBFB 100%);} */
/* .prices-top-section{background:linear-gradient(to top left, #FBFBFB 0%, #FBFBFB 50%, #fff 50%, #fff 100%);} */
.prices-top-section{background-color:#fff; padding:50px 0; height:auto; position: relative; width:100vw;}
.prices-top-section::before{border-bottom:180px solid #FBFBFB; border-left:100vw solid transparent; bottom:38%; content:' '; position:absolute; width:0;}
.prices-top-section::after{background-color:#FBFBFB; bottom:0px; content:''; height:38%; position:absolute; width:100%;}
.prices-top-section > .container{position:relative; z-index:1;}
.prices-top-section .heading{font-size:30px; font-weight:600;}
.prices-top-section .text{color:#6E939C;}
.prices-top-section .blocks{display:flex; justify-content:space-between; margin-top:50px;}
.prices-top-section .block{width:254px;}
.prices-top-section .block .img-block{align-items:center; background-color:#fff; border-radius:10px; -o-border-radius:10px; -moz-border-radius:10px; -webkit-border-radius:10px; box-shadow:0 0 30px #eee; display:flex; flex-direction:column; justify-content:center; height:254px; margin-bottom:20px; width:254px;}
.prices-top-section .block .img-block img{max-width:118px;}
.prices-top-section .block:first-child{margin-top:100px;}
.prices-top-section .block:nth-child(2){margin-top:50px;}
.prices-top-section .block .heading{font-size:20px; font-weight:600;}
.prices-top-section .block .text{color:#6E939C;}

.prices-bottom-section{background-color:#FBFBFB; padding:50px 0 80px;}
.prices-bottom-section .heading{font-size:40px; font-weight:700;}
.prices-bottom-section .text{color:#6E939C; font-size:20px;}
.prices-bottom-section .packages{display:flex; justify-content:space-between; margin-top:50px;}
.prices-bottom-section .single-package{width:256px;}
.prices-bottom-section .single-package .top-block{background-color:#fff; border:1px solid #ccc; border-radius:5px; height:205px; padding:40px 20px; position:relative; text-align:center;}
.prices-bottom-section .single-package .package-type{color:#962C08; font-size:25px; font-weight:500; text-transform:uppercase;}
.prices-bottom-section .single-package .package-price{color:#003965; font-size:30px; font-weight:600;}
.prices-bottom-section .single-package .pay-per-month{color:#6E939C; font-size:16px;}
.prices-bottom-section .single-package .top-block .highlight-text{background-color:#F07E22; color:#fff; font-size:16px; position:absolute; top:-18px; padding:5px 10px; border-radius:5px; left:calc(50% - 60px); width:120px;}


.prices-bottom-section .single-package.active .top-block{background-color:#A24921; border-color:#A24921;}
.prices-bottom-section .single-package.active .top-block::after{content:''; display:block; position:absolute; bottom:-10px; left:calc(50% - 5px); width:0; height:0; border-left:10px solid transparent; border-right:10px solid transparent; border-top:10px solid #A24921;}
.prices-bottom-section .single-package.active .package-type{color:#fff;}
.prices-bottom-section .single-package.active .package-price{color:#fff;}
.prices-bottom-section .single-package.active .pay-per-month{color:#fff;}

.prices-bottom-section .single-package .bottom-block{background-color:#fff; border:1px solid #ccc; border-radius:0 0 5px 5px; height:320px; position:relative; margin-top:20px; padding:30px; text-align:center;}
.prices-bottom-section .single-package .ant-list-items{text-align:left;}
.prices-bottom-section .single-package .mat-ico{color:#F07E22; font-size:15px;}
.prices-bottom-section .single-package .ant-list-item-meta-avatar{margin-right:5px;}
.prices-bottom-section .single-package .ant-list-item-meta-title{color:#6E939C; font-weight:normal;}
.prices-bottom-section .single-package .ant-list-split .ant-list-item{border-bottom:0px none; padding:0px;}
.prices-bottom-section .single-package .bottom-block .btn-blue{border:0px none; border-radius:5px; display:inline-block; height:auto; position:absolute; bottom:30px; left:calc(50% - 65px); width:130px; text-align:center;}
/*      Prices Page End      */


/*      Freelancer Profile Page Start     */
.freelancer-profile-page .main-content{padding-top:0px;}
.banner-block.profile-bg-img .profile-banner{width:100%; height:350px; position:absolute; background-size:cover; background-position:center center; background-repeat:no-repeat;}
.banner-block.profile-bg-img .profile-banner::before{position:absolute; content:''; display:block; width:100%; height:100%; background-color:rgba(0,0,0,.3);}
.banner-block.profile-bg-img{display:flex; flex-direction:column; justify-content:center; margin-bottom:50px; min-height:327px; position:relative;}
.banner-block.profile-bg-img .container{position:relative;}
.banner-block.profile-bg-img .profile-details{color:#fff; display:flex; position:absolute; top:-24px; left:0px;}
.banner-block.profile-bg-img .profile-img{width:269px;}
.banner-block.profile-bg-img .profile-img img{border-radius:5px;}
.banner-block.profile-bg-img .profile-basic-details{padding:60px 50px;}
.banner-block.profile-bg-img .profile-basic-details .verified-text{font-size:13px; font-weight:600;}
.banner-block.profile-bg-img .profile-details .name{color:#fff; font-size:30px; font-weight:600;}
.banner-block.profile-bg-img .profile-details .name a{color:#fff; font-size:14px;}
.banner-block.profile-bg-img .profile-details .location{margin:10px 0;}
.banner-block.profile-bg-img .profile-details .location .mat-ico{font-size:14px;}
.banner-block.profile-bg-img .profile-details .ratings-reviews{display:flex; font-size:18px; font-weight:500;}
.banner-block.profile-bg-img .profile-details .rating-text{align-self:flex-end; line-height:1; margin-left:15px; padding-right:15px; border-right:1px solid; margin-right:15px;}
.banner-block.profile-bg-img .profile-details .add-review{align-self:flex-end; color:#fff; line-height:1;}

.freelancers-profile .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color:inherit;}
.freelancers-profile .left-right-column{position:relative; z-index:100;}
.freelancers-profile-left-section{background-color:#fff; border-top:5px solid #F07E22; padding:50px 20px; margin-top:50px;}
.freelancers-profile-left-section .section:not(:last-child){margin-bottom:30px;}
.freelancers-profile-left-section .section .heading{font-size:18px; font-weight:500;}
.freelancers-profile-left-section .section.buttons{display:flex; justify-content:space-between;}
.freelancers-profile-left-section .section.buttons button{padding:5px 10px; width:48%;}
.freelancers-profile-left-section .section.buttons .btn-blue{border-radius:5px; border:1px solid #003965; height:auto;}
.freelancers-profile-left-section .section.buttons .btn-blue:hover{border-color:#003965; color:#fff;}
.freelancers-profile .btn-blank-brown{font-size:inherit; margin-bottom:8px; margin-right:5px; padding:3px 8px;}
.freelancers-profile .tags .total-other{color:#A24921;}

.freelancers-profile-left-section .social-links-text{margin-bottom:30px;}
.freelancers-profile-left-section ul.social-links{margin-bottom:0px;}
.freelancers-profile-left-section ul.social-links li{margin-bottom:0px; margin-right:10px;}
.freelancers-profile-left-section ul.social-links li a{align-items:center; border-radius:50%; border:1px solid #828282; display:flex; flex-direction:column; height:34px; justify-content:center; padding:0px; text-align:center; width:34px;}
.freelancers-profile-left-section ul.social-links li a:hover{border-color:#f07e22;}
.freelancers-profile-left-section ul.social-links li a:hover .ico.facebook{background-position:-13px -38px; height:18px; width:10px;}
.freelancers-profile-left-section ul.social-links li a:hover .ico.twitter{background-position:-42px -37px; height:18px; width:20px;}
.freelancers-profile-left-section ul.social-links li a:hover .ico.linkedin{background-position:-78px -37px; height:18px; width:20px;}

.freelancers-profile-right-section .ant-tabs-bar{border:0px none; margin-bottom:25px;}
.freelancers-profile-right-section .ant-tabs-tab{font-size:13px; margin-right:15px; padding:6px 10px; border-radius:5px; text-transform:uppercase;}
.freelancers-profile-right-section .ant-tabs-tab-active,
.freelancers-profile-right-section .ant-tabs-tab:hover{background-color:#F07E22; color:#fff;}
.freelancers-profile-right-section .ant-tabs-top .ant-tabs-ink-bar-animated{display:none !important;}

.freelancers-profile-right-section .section{background-color:#fff; color:#003965; margin-bottom:20px; padding:30px;}
.freelancers-profile-right-section .section .heading{color:#F07E22; font-size:20px; font-weight:500; margin-bottom:20px;}
.freelancers-profile-right-section .section .small-text{}
.freelancers-profile-right-section .section .title{font-size:18px; font-weight:500; margin-bottom:10px;}
.freelancers-profile-right-section .section .short-desc{font-weight:500; font-size:15px;}
.freelancers-profile-right-section .section .text{color:#6E939C;}

.freelancers-profile-right-section .blocks .block{border:1px solid #D6D6D6; border-radius:5px; box-shadow:0 0 10px #eee; margin-bottom:20px; padding:20px; position:relative;}
.freelancers-profile-right-section .experience-details .block .small-text{border:1px solid #003965; border-radius:20px; display:inline-block; padding:2px 10px; margin-bottom:5px;}
.freelancers-profile-right-section .text-center .btn{font-size:14px; font-weight:500; padding:5px 10px; border-radius:5px; margin-top:20px; display:inline-block;}

.freelancers-profile-right-section .certification-details .block{margin-left:31px; margin-top:51px; padding:30px;}
.freelancers-profile-right-section .certification-details .num{background-color:#003965; border-radius:50%; align-items:center; color:#fff; display:flex; flex-direction:column; justify-content:center; font-size:25px; font-weight:500; height:62px; width:62px; position:absolute; top:-31px; left:-31px;}
.freelancers-profile-right-section .certification-details .block:nth-child(2n) .num{background-color:#A24921;}
.freelancers-profile-right-section .certification-details .details{justify-content:space-between;}

.freelancers-profile-right-section .employment-details .ant-timeline-item .block{border:1px solid #E3E3E3; border-top:5px solid #928A87; padding:20px; text-align:left;}
.freelancers-profile-right-section .employment-details .ant-timeline-item:nth-child(2n+2) .block{border:1px solid#003965; border-top:5px solid#003965;}
.freelancers-profile-right-section .employment-details .ant-timeline-item:nth-child(2n+3) .block{border:1px solid #A24921; border-top:5px solid #A24921;}
.freelancers-profile-right-section .employment-details .title{margin-bottom:0px;}
.freelancers-profile-right-section .employment-details .title + .text{margin-bottom:5px;}
.freelancers-profile-right-section .employment-details .time + .text{margin-bottom:5px;}
.freelancers-profile-right-section .employment-details .bold-text{font-weight:500; margin-right:3px;}

.freelancers-profile-right-section .ant-timeline-item{}
.freelancers-profile-right-section .ant-timeline-item .ant-timeline-item-head{background-color:#928A87; border:0px none; height:15px; width:15px; margin-left:-6px; margin-top:26px;}
.freelancers-profile-right-section .ant-timeline-item .ant-timeline-item-content,
.freelancers-profile-right-section .ant-timeline-item-right .ant-timeline-item-content{position:relative; width:calc(50% - 25px) !important;}
.freelancers-profile-right-section .ant-timeline-item-left .ant-timeline-item-content{left:50%;}
.freelancers-profile-right-section .ant-timeline-item .ant-timeline-item-tail{height:100%;}
.freelancers-profile-right-section .ant-timeline-item-last .ant-timeline-item-tail{display:block; height:calc(100% - 35px);}

.freelancers-profile-right-section .ant-timeline-item-content::after{width:0; height:0; border-top:10px solid transparent; border-bottom:10px solid transparent; position:absolute; top:30px; right:-10px; content:''; display:block;}

.freelancers-profile-right-section .ant-timeline-item-right .ant-timeline-item-content::after{border-left:10px solid #928A87;}
.freelancers-profile-right-section .ant-timeline-item-left .ant-timeline-item-content::after{border-right:10px solid #928A87; left:-10px; right:auto;}
.freelancers-profile-right-section .employment-details .ant-timeline-item:nth-child(2n+2) .ant-timeline-item-content::after{border-left-color:#003965;}
.freelancers-profile-right-section .employment-details .ant-timeline-item:nth-child(2n+3) .ant-timeline-item-content::after{border-right-color:#A24921;}

.freelancers-profile-right-section .portfolio-tab{background-color:#fff; padding:40px;}
.freelancers-profile-right-section .portfolio-tab .blocks{display:flex; flex-wrap:wrap; justify-content:space-between;}
.freelancers-profile-right-section .portfolio-tab .block{border:0px none; box-shadow:none; padding:0px; margin-bottom:30px; width:335px;}
.freelancers-profile-right-section .portfolio-tab .img-block{display:block;}
.freelancers-profile-right-section .portfolio-tab img{border-radius:5px; width:100%;}
.freelancers-profile-right-section .portfolio-tab .title{color:#003965; font-size:18px; font-weight:500; margin:10px 0;}

.freelancers-profile .products-offers-tab{background-color:#fff; padding:40px;}
.freelancers-profile .products-offers-tab .single-offer{border:1px solid #D5D5D5; border-radius:5px; box-shadow:none; padding:30px; position:relative; width:320px;}
.freelancers-profile .products-offers-tab .top-block{border-radius:2px; display:flex; justify-content:center; flex-direction:column; height:157px; overflow:hidden;}
.freelancers-profile .products-offers-tab .top-block img{border:0px none; width:100%;}
.freelancers-profile .products-offers-tab .featured-text{background-color:#962C08; color:#fff; font-size:15px; font-weight:500; padding:3px 8px; position:absolute; top:40px; border-radius:0 5px 5px 0; left:0px;}
.freelancers-profile .products-offers-tab .wishlist-count{background-color:#F3F6F9; color:#003965; font-weight:500; padding:3px 8px; position:absolute; top:40px; border-radius:5px 0 0 5px; right:30px;}
.freelancers-profile .products-offers-tab .title{color:#003965; font-size:17px; font-weight:500; line-height:1.2; margin:10px 0;}
.freelancers-profile .products-offers-tab .last-activity{color:#6E939C;}
.freelancers-profile .products-offers-tab .slick-slide > div{margin:0 15px;}
.freelancers-profile .products-offers-tab .ratings-price,
.freelancers-profile .products-offers-tab .ratings{display:flex;}
.freelancers-profile .products-offers-tab .ratings-price{justify-content:space-between;}
.freelancers-profile .products-offers-tab .rating-text{color:#003965; align-self:center; margin-left:5px;}
.freelancers-profile .products-offers-tab .price-text{color:#003965; align-self:center; margin-left:5px; font-weight:500;}
.freelancers-profile .products-offers-tab .price-text .text{color:#6E939C; font-weight:normal; margin-right:3px;} 
.freelancers-profile .products-offers-tab .single-offer .bottom-block{display:flex; justify-content:space-between; margin-top:20px;}
.freelancers-profile .products-offers-tab .single-offer .sold-text{color:#6E939C; display:flex; flex-direction:column; line-height:1;}
.freelancers-profile .products-offers-tab .single-offer .sold-text .num{color:#003965; font-size:18px; font-weight:500;}
.freelancers-profile .products-offers-tab .single-offer .buttons-block{display:flex;}
.freelancers-profile .products-offers-tab .single-offer .btn{padding:2px 15px; border-radius:5px; margin-left:5px; align-self:center;}

.freelancers-profile .reviews-tab .block.orange-light-bg{background-color:rgba(240, 126, 34, 0.20); border-radius:10px; padding:30px;}
.freelancers-profile .reviews-tab .block.blue-light-bg,
.freelancers-profile .purchase-tab .block.blue-light-bg{background-color:rgba(0, 57, 101, 0.20); border-radius:10px; display:flex; justify-content:space-between; padding:15px;}

.freelancers-profile .reviews-tab .block.review,
.freelancers-profile .purchase-tab .block.review{display:flex; justify-content:space-between; padding:30px 0;}
.freelancers-profile .reviews-tab .block.review .img-block,
.freelancers-profile .purchase-tab .block.review .img-block{width:52px;}
.freelancers-profile .reviews-tab .block.review .text-block,
.freelancers-profile .purchase-tab .block.review .text-block{width:calc(100% - 200px);}
.freelancers-profile .reviews-tab .block.review .name,
.freelancers-profile .purchase-tab .block.review .name{display:flex;}
.freelancers-profile .reviews-tab .block.review .name .text,
.freelancers-profile .purchase-tab .block.review .name .text{color:#003965; font-size:18px; font-weight:600; margin-right:20px;}
.freelancers-profile .reviews-tab .block.review .added-block,
.freelancers-profile .purchase-tab .block.review .added-block{font-weight:600; text-align:right; width:100px;}
.freelancers-profile .reviews-tab .links,
.freelancers-profile .purchase-tab .links{font-size:13px; font-weight:500;}
.freelancers-profile .reviews-tab .links > span,
.freelancers-profile .purchase-tab .links > span{margin-right:25px;}
.freelancers-profile .reviews-tab .links .ico,
.freelancers-profile .purchase-tab .links .ico{margin-right:5px;}
.freelancers-profile .reviews-tab .ratings,
.freelancers-profile .purchase-tab .ratings{display:flex;}
.freelancers-profile .reviews-tab .ratings .ant-rate,
.freelancers-profile .purchase-tab .ratings .ant-rate{display:flex; justify-content:center; margin-top:-4px;}
.freelancers-profile .reviews-tab .rating-text,
.freelancers-profile .purchase-tab .rating-text{align-self:center; margin-left:10px;}

.freelancers-profile .reviews-tab .block.blue-light-bg .left-block,
.freelancers-profile .purchase-tab .block.blue-light-bg .left-block{border-radius:5px; overflow:hidden; display:flex; justify-content:center; flex-direction:column; height:93px; width:106px;}
.freelancers-profile .reviews-tab .block.blue-light-bg .mid-block,
.freelancers-profile .purchase-tab .block.blue-light-bg .mid-block{display:flex; flex-direction:column; justify-content:center; width:calc(100% - 250px);}
.freelancers-profile .reviews-tab .block.blue-light-bg .right-block,
.freelancers-profile .purchase-tab .block.blue-light-bg .right-block{font-size:24px; font-weight:700; margin-top:15px; text-align:right; width:100px;}

.freelancers-profile .reviews-tab .block.review.reply{padding-left:76px;}
.freelancers-profile .reviews-tab .block.review.reply .text-block{border-left:1px solid #EAEAEA; padding-left:25px; width:calc(100% - 75px);}

.profile-wrapper{width:100%}

.ant-tabs.profile-tabs{overflow:visible;overflow:initial}
.profile-tabs .ant-tabs-content-holder{padding:35px; background-color:#fff; box-shadow:0 10px 20px rgba(0,0,0,.2)}
.profile-tabs .ant-tabs-nav-list{background-color:#fff; border-radius:35px; display:flex; justify-content:space-evenly;}
.profile-tabs .ant-tabs-nav-list .ant-tabs-tab{padding:0;}
.profile-tabs .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn{padding:10px 20px}
.profile-tabs .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active{background-color:#003965; border-radius:35px;}
.profile-tabs .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active strong{color:#fff}

.ant-tabs.profile-tabs .page-heading{font-size:20px; margin-bottom:50px}

.profile-tabs .ant-tabs-nav-list .ant-tabs-ink-bar{display:none}

/*      Freelancer Profile Page End      */

/*      Projects Details Page Start     */
.page-heading{color:#003965; font-size:30px; margin-bottom:40px;}
.page-subheading{color:#6E939C; font-size:20px; font-weight:500;}

.projects-details .page-subheading{margin-bottom:30px;}
.view-details-left-blk .top-section{background-color:#fff; margin-bottom:20px; padding:30px;}
.view-details-left-blk .top-section .block-heading{background-color:transparent; border:none; color:#003965; font-size:30px; font-weight:500;}
.projects-details-left-section .top-section .details{display:flex; justify-content:space-between; padding:15px; border:1px solid #D9D9D9; border-radius:5px; margin-top:30px;}
.projects-details-left-section .top-section .details .icons{align-self:center; display:flex; justify-content:space-between; text-align:center; width:35%;}
.projects-details-left-section .top-section .details .right-block{align-items:center; display:flex; width:60%;}
.projects-details-left-section .top-section .details .right-block .profile-images{display:flex; margin-right:10px;}
.projects-details-left-section .top-section .details .right-block img{}
.projects-details-left-section .top-section .details .right-block .profile-img-block{border-radius:5px; display:inline-block; width:50px; height:50px; overflow:hidden; text-align:center;}
.projects-details-left-section .top-section .details .right-block .profile-img-block + .profile-img-block{margin-left:-25px;}
.projects-details-left-section .top-section .details .right-block .profile-img-block.with-text{background-color:#A24921; color:#fff; display:flex; flex-direction:column; font-size:18px; font-weight:500; justify-content:center;}
.projects-details-left-section .top-section .details .right-block .profile-text-block{color:#6E939C; font-size:18px; font-weight:500;}

/* .projects-details-left-section .ant-tabs{color:rgba(0, 0, 0, 0.65);}
.projects-details-left-section .ant-tabs-nav .ant-tabs-tab{border-radius:5px; color:#6E939C; font-size:13px; margin-right:0px; padding:5px 20px; text-transform:uppercase;}
.projects-details-left-section .ant-tabs-bar{border-bottom:0px none;}
.projects-details-left-section .ant-tabs-nav .ant-tabs-tab-active{background-color:#003965; }
.projects-details-left-section .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn{color:#fff;}
.projects-details-left-section .ant-tabs-ink-bar-animated{display:none !important;}
.projects-details-left-section .ant-tabs-tabpane{background-color:#fff; padding:30px;}
.projects-details-left-section .ant-tabs-nav-wrap{justify-content:flex-start !important;} */

.projects-details-left-section .main-details .first-section{color:#6E939C; display:flex; margin-bottom:25px; text-align:center;}
.projects-details-left-section .main-details .first-section .block:not(:last-child){margin-right:50px;}
.projects-details-left-section .main-details .first-section .bold-text{color:#003965; font-size:20px; font-weight:500;}
.projects-details-left-section .main-details .first-section .text{font-size:18px;}

.projects-details-left-section .desc-section{margin-bottom:40px;}
.projects-details-left-section .attachments-section{margin-bottom:60px;}
.projects-details-left-section .attachments-section .blocks,
.projects-details-left-section .attachments-section .block{display:flex;}
.projects-details-left-section .attachments-section .block:not(:last-child){margin-right:15px;}
.projects-details-left-section .attachments-section .block .ico{margin-right:5px;}
.projects-details-left-section .signup-section,
.projects-details-left-section .section.no-messages{border:1px solid #D9D9D9; border-radius:5px; display:flex; justify-content:space-between; padding:35px;}
.projects-details-left-section .signup-section .block-title,
.projects-details-left-section .section.no-messages .block-title{color:#003965; font-size:20px; font-weight:500;}
.projects-details-left-section .signup-section .block-text,
.projects-details-left-section .section.no-messages .block-text{color:#6E939C; font-size:14px;}
.projects-details-left-section .signup-section .right,
.projects-details-left-section .section.no-messages .right{display:flex; flex-direction:column; justify-content:center;}
.projects-details-left-section .signup-section .dark-blue-btn,
.projects-details-left-section .section.no-messages .dark-blue-btn{font-weight:500; padding:9px 35px;}

.no-messages + .clarification-details{margin-top:40px;}
.projects-details-left-section .clarification-details .section{display:flex; justify-content:space-between; margin-bottom:20px;}
.projects-details-left-section .clarification-details .block-title{color:#003965; font-size:30px; font-weight:500;}
.projects-details-left-section .section .btn{font-weight:500; height:auto; padding:9px 35px;}
.projects-details-left-section .section .btn.add-milestone{float:right; margin-bottom:20px; padding:5px 20px;}
.projects-details-left-section .buttons-block .btn{padding:5px 20px;}

.projects-details-left-section .new-proposal-details{border-top:20px solid #fafafa; margin-left:-60px; margin-top:30px; padding:30px 60px 0px; width:calc(100% + 120px);}
.projects-details-left-section .new-proposal-details .block-title{color:#003965; font-size:30px; font-weight:500; margin-bottom:20px;}
.ant-input{background-color:#F8F8F8; font-size:16px; padding:8px 15px;}
/* .new-proposal-details .ant-upload.ant-upload-drag{background-color:#F8F8F8; border:0px none; padding:8px 15px;}
.new-proposal-details .ant-upload.ant-upload-btn{padding:10px 0;}
.new-proposal-details .ant-upload-drag-container{align-items:center; display:flex !important;}
.new-proposal-details .ant-upload-drag-icon{margin-bottom:0px !important; margin-right:20px;}
.new-proposal-details .ant-upload.ant-upload-drag .btn{font-size:20px; text-transform:uppercase;} */
.new-proposal-details .milestones-section{margin-bottom:20px;}
.new-proposal-details .milestones-section .ant-form-item{margin-bottom:10px;}
.new-proposal-details .milestone-details{border:1px solid #E3E3E3; border-radius:5px; display:flex; margin-bottom:20px; padding:10px;}
.new-proposal-details .milestone-details .block{padding:5px 10px; position:relative; text-align:center; width:33%;}
.new-proposal-details .milestone-details .block:not(:last-child){border-right:1px solid #6E939C;}
.new-proposal-details .milestone-details .label{color:#003965; font-weight:500; margin-right:5px; text-transform:uppercase;}
.new-proposal-details .milestone-details .text{color:#6E939C; font-weight:500;}
.align-right{text-align:right;}

.milestones-block .ant-row{position:relative;}
.milestones-block .ant-row.ant-form-item{margin-bottom:10px;}
.milestones-block .remove-icon{background-color:#6E939C; color:#fff; padding:9px; position:absolute; right:0px; top:0px; z-index:9; cursor:pointer;}

.ant-form label.cloud-uploader{width:100%; padding:15px; justify-content:center; align-items:center; display:flex; background-color:rgba(0, 0, 0, .01); border:1px solid rgba(0, 0, 0, .07); transition:.2s all ease-in-out; cursor:pointer; margin-bottom:50px; flex-direction:column; font-size:65px !important; color:rgba(0,0,0,.1) !important;}
.ant-form label.cloud-uploader p{font-size:15px; color:rgba(0,0,0,.4);}
.ant-form label.cloud-uploader:hover{background-color:rgba(0, 0, 0, .02); border:1px solid rgba(0, 0, 0, .1); box-shadow:0 5px 15px rgba(0,0,0,.02);}

.projects-details-left-section  .ant-row-bottom{font-size:16px;}

.new-proposal-wrap{text-align:center; padding:35px 0;margin:30px 0;}
.new-proposal-wrap h3{color:#414141;font-size:25px;line-height:38px;margin:0;}
.new-proposal-wrap p{color:#AAA7A7;font-size:14px;line-height:21px;margin:20px 0 15px;}

form{font-size:16px;}
form label,
.ant-form label{color:#003965 !important; font-size:16px !important; font-weight:500;}
form .ant-form-item{font-size:16px;}
form p{color:#6E939C;}
form a{color:#003965; font-weight:600;}
form .buttons-block button{font-size:20px; height:auto; text-transform:uppercase;}
form .buttons-block button:not(:last-child){margin-right:15px;}
form .note{font-size:16px; text-align:right;}

.ant-upload.ant-upload-drag{background-color:#F8F8F8 !important; border:0px none; padding:8px 15px;}
.ant-upload.ant-upload-btn{padding:10px 0;}
.ant-upload-drag-container{align-items:center; display:flex !important;}
.ant-upload-drag-icon{margin-bottom:0px !important; margin-right:20px !important;}
.ant-upload.ant-upload-drag .btn{font-size:20px; text-transform:uppercase;}

.clarification-chat .block.question{display:flex; justify-content:space-between; padding:30px 0;}
.clarification-chat .block.question .img-block{width:52px;}
.clarification-chat .block.question .text-block{width:calc(100% - 200px);}

.clarification-chat .block.question .name{align-items:flex-end; display:flex;}
.clarification-chat .block.question .name .text{color:#003965; font-size:18px; font-weight:600; margin-right:20px;}
.clarification-chat .block.question .added-block{font-weight:600; text-align:right; width:100px;}
.clarification-chat .links{font-size:13px; font-weight:500;}
.clarification-chat .links > span{margin-right:25px;}
.clarification-chat .links .ico{margin-right:5px;}

.clarification-chat .block.blue-light-bg .left-block{border-radius:5px; overflow:hidden; display:flex; justify-content:center; flex-direction:column; height:93px; width:106px;}
.clarification-chat .block.blue-light-bg .mid-block{display:flex; flex-direction:column; justify-content:center; width:calc(100% - 250px);}
.clarification-chat .block.blue-light-bg .right-block{font-size:24px; font-weight:700; margin-top:15px; text-align:right; width:100px;}

.clarification-chat .block.question.reply{padding-left:76px;}
.clarification-chat .block.question.reply .text-block{border-left:2px solid #EAEAEA; padding-left:40px; width:calc(100% - 240px);}
.clarification-chat .ico{margin-right:5px;}

.profile-basic-details.right-blk{display:flex; justify-content:center; flex-direction:column; margin-bottom:60px;}
.profile-basic-details.right-blk .top{background-color:#003965; height:100px;}
.profile-basic-details.right-blk .bottom{align-items:center; background-color:#ffff; display:flex; flex-direction:column; padding:0px 50px 30px;}
.profile-basic-details.right-blk .ant-avatar{align-self:center; margin-top:-50px;}
.profile-basic-details.right-blk .name{color:#003965; font-size:25px; font-weight:500;}
.profile-basic-details.right-blk .location{color:#6e939c; margin:10px 0;}
.profile-basic-details.right-blk .location .mat-ico{color:#6e939c; font-size:14px;}
.profile-basic-details.right-blk .ratings-reviews{display:flex; font-size:18px; font-weight:500;}
.profile-basic-details.right-blk .rating-text{align-self:flex-end; color:#6e939c; margin-left:15px;}
.profile-basic-details.right-blk .last-project{border:1px solid #CCCCCC; border-radius:5px; color:#6E939C; margin-bottom:20px; padding:3px 10px; margin-top:20px;}
.profile-basic-details.right-blk .last-project .date-text{color:#003965; font-weight:500;}
.profile-basic-details.right-blk .other-details{color:#6E939C; display:flex; justify-content:space-between; text-align:center; width:100%;}
.profile-basic-details.right-blk .other-details > div.flex-col{align-items:center; display:flex; flex-direction:column; width:30%;}
.profile-basic-details.right-blk .other-details .num{color:#003965; font-size:20px; font-weight:500;}
.profile-basic-details.right-blk .other-details .ico{margin-bottom:10px;}

.related-projects-section .heading{font-size:40px; font-weight:600;}
.related-projects-listing{}
.related-projects-listing .slick-slide > div{margin:0 15px;}
.related-projects-listing .single-project{background-color:#fff; border:1px solid #bebebe; border-radius:5px; color:#003965; height:auto; position:relative;}
.related-projects-listing .single-project:hover{border-color:#f07e22; box-shadow:0 0 20px #eee;}
.related-projects-listing .single-project .top-block{border-bottom:1px solid #c7c7c7; padding:30px;}
.related-projects-listing .featured-text{background-color:#962C08; border-top-left-radius:5px; border-bottom-left-radius:5px; color:#fff; font-size:15px; font-weight:500; padding:3px 10px; position:absolute; top:30px; right:0px;}
.related-projects-listing .title{font-size:20px; font-weight:600; margin-bottom:8px; min-height:63px; width:76%;}
.related-projects-listing .text{color:#6E939C; font-size:14px; margin-bottom:10px;}

.related-projects-listing .links{font-size:14px; font-weight:600;}
.related-projects-listing .links > span{margin-right:25px;}
.related-projects-listing .links .ico{margin-right:5px;}
.related-projects-listing .single-project .bottom-block{display:flex; justify-content:space-between; padding:20px 30px;}
.related-projects-listing .single-project .price-block{align-self:center; font-size:24px; font-weight:300;}
.related-projects-listing .single-project .price-block .bold{font-weight:bold;}
.related-projects-listing .single-project .btn{border-radius:5px;}
/*      Projects Details Page End     */


/*      CMS Page Start      */
.cms-page{color:#6E939C;}
.cms-page b{color:#003965;}
.cms-page a{color:#F07E22;}
.cms-page h2{color:#003965; font-size:20px;}
.cms-page li::marker{color:#003965; font-size:20px; font-weight:500;}
.cms-page .ant-checkbox .ant-checkbox-inner,
.addons-section .ant-checkbox .ant-checkbox-inner{border-color:#F07E22;}
.cms-page .ant-checkbox-checked .ant-checkbox-inner,
.addons-section .ant-checkbox-checked .ant-checkbox-inner{background-color:#F07E22; border-color:#F07E22;}
.cms-page .ant-layout-content.main-content,
.prices-page .ant-layout-content.main-content,
.blogs-listing .ant-layout-content.main-content{padding-top:0px;}
.cms-page .ant-layout-content.main-content .main-content-title,
.prices-page .ant-layout-content.main-content .main-content-title,
.blogs-listing .ant-layout-content.main-content .main-content-title,
.how-we-work-page .ant-layout-content.main-content .main-content-title{display:none;}

.cms-page .banner-block{height:510px;}
.cms-page .inner-page .heading{font-size:30px; margin-bottom:20px;}
.cms-page .inner-page .heading.lg{font-size:41.6px; font-weight:600; line-height:1.2em; margin-bottom:0px; margin-top:5px;}
.cms-page .banner-block .heading{font-size:60px; font-weight:bold;}
.cms-page .inner-page .small-heading{color:#A24921; font-size:14px; margin-bottom:10px; text-transform:uppercase;}
.cms-page .inner-page .small-heading.lg{color:#962C08; font-size:20.8px; font-weight:500; margin:0px;}
.cms-page .img-text-section .text-block .text{color:#6E939C;}
.cms-page .img-text-section > div{display:flex;}

.cms-page .ant-tabs-bar{border-bottom:0px none;}
.cms-page .ant-tabs-nav-scroll{display:flex; justify-content:center;}
.cms-page .ant-tabs-tab{border:1px solid #962C08; color:#962C08; padding:10px 20px; margin-bottom:1px; margin-right:0px; min-width:150px; text-align:center;}
.cms-page .ant-tabs-tab:first-child{border-bottom-left-radius:5px; border-top-left-radius:5px;}
.cms-page.trust-safety .ant-tabs-tab,
.cms-page.terms-of-service .ant-tabs-tab{margin-left:0px;}
.cms-page .ant-tabs-tab:last-child,
.cms-page.trust-safety .ant-tabs-tab:nth-child(2),
.cms-page.terms-of-service .ant-tabs-tab:nth-child(2){border-bottom-right-radius:5px; border-top-right-radius:5px;}
.cms-page.trust-safety .ant-tabs-tab .ant-tabs-tab-btn,
.cms-page.terms-of-service .ant-tabs-tab .ant-tabs-tab-btn{margin:0 auto;}
.cms-page.trust-safety .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.cms-page.terms-of-service .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color:#fff;}
.ant-tabs-top > .ant-tabs-nav::before{border-bottom:0px none;}
.cms-page .ant-tabs-tab-active,
.cms-page .ant-tabs-tab:hover{background-color:#962C08; color:#fff;}
.cms-page .content img{margin-bottom:20px;}
.cms-page.trust-safety .ant-tabs-ink-bar-animated,
.cms-page.terms-of-service .ant-tabs-ink-bar-animated{display:none !important;}

.our-team .single-member{border:1px solid #DBDBDB; border-radius:5px; padding:20px; display:flex !important; justify-content:space-between;}
.our-team .single-member:hover{border-color:#FC9E00; box-shadow:0 0 10px rgba(144,144,144, .16);}
.our-team .slick-slide > div{margin:0 15px;}
.our-team .single-member .left-block{width:136px;}
.our-team .single-member .right-block{text-align:left; width:calc(100% - 150px);}
.our-team .single-member .left-block img{border-radius:50%; height:136px; width:136px;}
.our-team .name{color:#003965; font-size:22px; font-weight:600;}
.our-team .designation{font-size:15px; margin-bottom:12px;}
.our-team .btn{border-radius:5px; padding:6px 20px;}


.our-team.about .single-member{border:1px solid #DBDBDB; border-radius:0px; box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.13); margin-bottom:10px; margin-top:93px; padding:0px 45px 0px 45px; position:relative;}
.our-team.about .single-member:hover{border-color:inherit; box-shadow:inherit;}
.our-team.about .slick-slide > div{margin:0 15px;}
.our-team.about .img-block{border-radius:50%; -o-border-radius:50%; -moz-border-radius:50%; -webkit-border-radius:50%; position:absolute; left:calc(50% - 93px); top:-93px; width:186px;}
.our-team.about .img-block .user-pic{max-height:100%; max-width:100%;}
.our-team.about .img-block .social-img{border-radius:50%; -o-border-radius:50%; -moz-border-radius:50%; -webkit-border-radius:50%; bottom:15px; height:52px; position:absolute; right:-10px; width:52px;}
.our-team.about .text-block{margin-top:100px; margin-bottom:50px; text-align:center;}
.our-team.about .name{color:#003965; font-size:22px; font-weight:600;}
.our-team.about .designation{font-size:15px; margin-bottom:40px; position:relative;}
.our-team.about .designation::after{background-color:#fc9e00; bottom:-10px; content:''; display:block; height:3px; left:calc(50% - 40px); position:absolute; width:80px;}
.our-team.about .text{color:#818181; max-height:0px; overflow:hidden; transition:max-height 0.5s ease-in-out;}
.our-team.about .single-member:hover{background-color:#414141;}
.our-team.about .single-member:hover .name,
.our-team.about .single-member:hover .designation{color:#fc9e00;}
.our-team.about .single-member:hover .text{max-height:1000px;}


/*      CMS Page End      */

/*      Category Listing Page Start     */
.child-category-section .ant-tabs-nav-list{background-color:rgb(248, 248, 248); border-top-left-radius:10px; border-top-right-radius:10px; margin-top:50px; overflow:hidden; padding:15px; width:100%;}
.child-category-section .ant-tabs-nav{margin-bottom:0px;}
.child-category-section .ant-tabs-tab.ant-tabs-tab-active,
.child-category-section .ant-tabs-tab:hover{background-color:#fff; border-radius:10px; -o-border-radius:10px; -moz-border-radius:10px; -webkit-border-radius:10px;}
.child-category-section .ant-tabs-tab-btn,
.child-category-section .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color:#6E939C; font-size:16px; padding:0 10px; text-align:center;}
.child-category-section .ant-tabs-tab-btn img{filter:grayscale(1); width:60px;}
.child-category-section .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn img,
.child-category-section .ant-tabs-tab:hover .ant-tabs-tab-btn img{filter:grayscale(0); width:60px}
.child-category-section .ant-tabs-ink-bar-animated{display:none;}

.child-category-section .ant-tabs-tabpane{border:1px solid #E5E5E5; border-bottom-left-radius:10px; border-bottom-right-radius:10px; color:#6E939C; font-size:15px; min-height:250px; overflow:hidden; padding:30px 40px 40px;}
.child-category-section .heading{color:#003965; font-size:28px; font-weight:500; margin-bottom:15px;}
.child-category-section .ant-tabs-tabpane a{color:#A24921;}

.categories-section.related{margin-top:30px;}
#root > .category .categories-section{padding-bottom:50px;}
#root > .category .sp-projects{padding-top:50px;}
/*      Category Listing Page End     */


/*      How We Work Page Start      */
.how-we-work-page .inner-page .heading{font-size:30px; font-weight:600;}

.how-we-work-page .main-block{background-image:url(/static/media/why-cogniticx-bg.6bb52ad3.png); background-repeat:no-repeat; background-position:center 380px; margin-top:60px;}
.how-we-work-page .main-block > .container > .heading{margin-bottom:50px;}
.how-we-work-page .main-block .blocks{display:flex; justify-content:space-between;}
.how-we-work-page .main-block .blocks .content-block{width:calc(100% - 400px);}
.how-we-work-page .main-block .blocks .image-block{align-items:center; display:flex; flex-direction:column; justify-content:center; width:375px;}

.how-we-work-page .main-block .content-block .point{display:flex; justify-content:space-between; line-height:2.5;}
.how-we-work-page .main-block .blocks .text-block{width:calc(100% - 70px);}
.how-we-work-page .main-block .blocks .img-block{margin-right:15px; width:58px;}
.how-we-work-page .point{color:#6E939C; margin-bottom:40px;}
.how-we-work-page .point .heading{color:#003965; font-size:20px; font-weight:500; line-height:1.5;}
.how-we-work-page .video-block{margin-top:30px;}

.how-we-work-page .client-sp-block{margin-top:100px;}
.how-we-work-page .faqs-block{margin-top:80px;}
.how-we-work-page .faqs-block .heading{font-size:40px; font-weight:600; margin-bottom:30px;}

.how-we-work-page .ant-collapse{background-color:transparent; border:0px none; font-size:20px; margin-bottom:10px; overflow:hidden;}
.how-we-work-page .ant-collapse-item .ant-collapse-header{background-color:#F8F8F8; font-size:25px; color:#003965; font-weight:500; padding:15px 40px 15px 25px;}
.how-we-work-page .ant-collapse-item-active .ant-collapse-header{background-color:#962C08; color:#fff; font-weight:600;}
.how-we-work-page .ant-collapse-content-box{color:#6E939C; padding:15px 25px;}
.how-we-work-page .ant-collapse > .ant-collapse-item{border-bottom:0px none; margin-bottom:5px;}


.how-we-work-page .client-sp-block .ant-tabs-bar{border-bottom:0px none;}
.how-we-work-page .client-sp-block .ant-tabs-nav-scroll{display:flex; justify-content:center;}
.how-we-work-page .client-sp-block .ant-tabs-tab{border:1px solid #962C08; color:#962C08; padding:10px 20px; margin-bottom:1px; margin-left:0px; min-width:150px; text-align:center;}
.how-we-work-page .client-sp-block .ant-tabs-tab:first-child{border-bottom-left-radius:5px; border-top-left-radius:5px;}
.how-we-work-page .client-sp-block .ant-tabs-tab:last-child,
.how-we-work-page .client-sp-block .ant-tabs-tab:nth-child(2){border-bottom-right-radius:5px; border-top-right-radius:5px;}
.how-we-work-page .client-sp-block .ant-tabs-tab .ant-tabs-tab-btn{font-size:16px; font-weight:500; margin:0 auto;}
.how-we-work-page .client-sp-block .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color:#fff;}
.how-we-work-page .client-sp-block .ant-tabs-top > .ant-tabs-nav::before{border-bottom:0px none;}
.how-we-work-page .client-sp-block .ant-tabs-tab-active,
.how-we-work-page .client-sp-block .ant-tabs-tab:hover{background-color:#962C08; color:#fff;}
.how-we-work-page .client-sp-block .content img{margin-bottom:20px;}
.how-we-work-page .client-sp-block .ant-tabs-ink-bar-animated{display:none !important;}

.how-we-work-page .client-sp-block .img-text-section{margin-top:50px;}
.how-we-work-page .client-sp-block .img-block{position:relative; padding:50px; padding-right:0px;}
.how-we-work-page .client-sp-block .img-text-section:not(.rev-row) .img-block{padding-left:0px; padding-right:50px;}
.how-we-work-page .client-sp-block .img-block::before{background-color:#FEE2B2; border-radius:5px; content:''; display:block; height:100%; position:absolute; left:0px; top:0px; width:calc(100% - 100px);}
.how-we-work-page .client-sp-block .img-text-section:not(.rev-row) .img-block::before{left:auto; right:0px;}
.how-we-work-page .client-sp-block .img-block img{z-index:999;}

.how-we-work-page .client-sp-block .num{background-color:#FEE2B2; border-radius:5px; -o-border-radius:5px; -moz-border-radius:5px; -webkit-border-radius:5px; color:#000; display:flex; flex-direction:column; font-size:24px; font-weight:600; justify-content:center; height:62px; margin-bottom:50px; text-align:center; width:62px;}
.how-we-work-page .client-sp-block .heading{font-size:20px; font-weight:600;}
.how-we-work-page .client-sp-block .block{color:#6E939C; line-height:1.5; padding:100px 125px;}
.how-we-work-page .client-sp-block .img-text-section:not(.rev-row) .block{padding-left:50px;}
.how-we-work-page .client-sp-block .block ul{padding-left:15px;}
.how-we-work-page .client-sp-block .block ul li{margin-bottom:15px; position:relative;}
.how-we-work-page .client-sp-block .block ul li::before{content:"."; color:#F07E22; font-size:30px; font-weight:bold; display:inline-block; left:-15px; line-height:0; position:absolute; top:1px;}

.how-we-work-page .faqs-block .ant-tabs-bar{border-bottom:0px none;}
.how-we-work-page .faqs-block .ant-tabs-nav-scroll{display:flex; justify-content:center;}
.how-we-work-page .faqs-block .ant-tabs-tab{border-radius:5px; color:#962C08; padding:5px 10px; margin-bottom:1px; margin-left:10px; min-width:100px; text-align:center;}
.how-we-work-page .faqs-block .ant-tabs-tab .ant-tabs-tab-btn{color:#B5B5C3; font-size:16px; margin:0 auto; text-transform:uppercase;}
.how-we-work-page .faqs-block .ant-tabs-tab:hover .ant-tabs-tab-btn,
.how-we-work-page .faqs-block .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color:#fff;}
.how-we-work-page .faqs-block .ant-tabs-top > .ant-tabs-nav{margin-bottom:40px;}
.how-we-work-page .faqs-block .ant-tabs-top > .ant-tabs-nav::before{border-bottom:0px none;}
.how-we-work-page .faqs-block .ant-tabs-tab-active,
.how-we-work-page .faqs-block .ant-tabs-tab:hover{background-color:#962C08; color:#fff;}
.how-we-work-page .faqs-block .content img{margin-bottom:20px;}
.how-we-work-page .faqs-block .ant-tabs-ink-bar-animated{display:none !important;}
.how-we-work-page .faqs-block .ant-collapse-arrow svg{height:20px; width:20px;}
/*      How We Work Page End      */


/*      Register Selection Start    */
.register-select-section .blocks{display:flex; justify-content:space-between; margin:50px 0;}
.register-select-section .block{align-items:center; border-radius:15px; box-shadow:0 0 10px rgba(144,144,144,.16); display:flex; flex-direction:column; justify-content:center; line-height:1.2; min-height:374px; text-transform:uppercase; width:48%;}
.register-select-section .block{box-shadow:0 0 10px rgba(0,0,0,.1); color:#fff; transition:.4s all ease-in-out}
.register-select-section .block:hover{box-shadow:0 0 15px rgba(252,158,0,.24); background-color:#F07E22; }
.register-select-section .block.orng-bg:hover{box-shadow:0 0 15px rgba(252,158,0,.48)}
.register-select-section .block .slim-text{font-size:46px; font-weight:300;}
.register-select-section .block .bold-text{font-size:69px; font-weight:600;}
.register-select-section .block .slim-text{color:#F07E22; transition:.2s all ease-in-out}
.register-select-section .block .bold-text{color:#003965; transition:.2s all ease-in-out}

.register-select-section .block:hover .slim-text{color:#fff;}
.register-select-section .block:hover .bold-text{color:#fff;}
/*      Register Selection End      */

/*      Dashboard Start       */
.ant-table-pagination.ant-pagination{justify-content:center;}
.dashboard-page .ant-table-pagination.ant-pagination li{min-width:24px; height:24px;}
.dashboard-page .ant-table-pagination.ant-pagination li button,
.dashboard-page .ant-table-pagination.ant-pagination li a{font-size:10px; line-height:2;}

.freelancer-dashboard-page .main-content{background-color:#F3F6F9; background-image:none; padding-top:0px;}
.freelancer-dashboard-page .main-content-title{display:none;}
.banner-block.dashboard-bg-img > img{height:236px; min-width:100%; top:0px;}
.freelancer-dashboard-page .page-heading,
.product-add .page-heading,
.products-view.freelancer-ac .page-heading{color:#fff; font-size:30px; font-weight:500; padding-top:70px; margin-bottom:30px !important;}
.freelancer-dashboard-page .banner-block.dashboard-bg-img > img{height:236px; top:0px;}
.freelancer-dashboard-page .page-heading{color:#fff; font-size:30px; font-weight:500; padding-top:50px; margin-bottom:20px;}
.freelancer-dashboard-page .profile-details{background-color:#fff; color:#003965; display:flex; justify-content:space-between;}
.freelancer-dashboard-page .banner-block.dashboard-bg-img .profile-details{border-radius:5px; padding:50px 30px;}
.freelancer-dashboard-page .banner-block.dashboard-bg-img  .profile-img{border:2px solid #05C941; border-radius:50%; height:90px; padding:3px; width:90px;}
.freelancer-dashboard-page .banner-block.dashboard-bg-img  .profile-img img{border-radius:50%;}
.freelancer-dashboard-page .user-details{display:flex; justify-content:space-between;}
.freelancer-dashboard-page .banner-block.dashboard-bg-img .profile-details{color:#003965;}
.freelancer-dashboard-page .banner-block.dashboard-bg-img .profile-basic-details{padding:0px 20px;}
.freelancer-dashboard-page .banner-block.dashboard-bg-img .profile-details .name{color:#003965;}

.freelancer-dashboard-page .banner-block .container-lg{color:#fff; line-height:1.2; position:relative; z-index:2;}

.freelancer-dashboard-page .profile-basic-details .name{margin-bottom:10px;}
.freelancer-dashboard-page .profile-basic-details .location{display:flex; align-items:center; margin-bottom:10px;}
.freelancer-dashboard-page .section .small-text{font-size:14px; margin-bottom:5px;}
.freelancer-dashboard-page .section .title{font-size:18px; font-weight:500; margin-bottom:5px;}
.freelancer-dashboard-page .banner-block .container-lg .text{color:#6E939C; font-size:14px;}
.freelancer-dashboard-page .section.details-section{max-width:450px; flex:1 1}
.freelancer-dashboard-page .section .price{font-weight:500;}

.freelancer-dashboard-page .ratings-section{border-right:1px solid #C6C6C6; display:flex; justify-content:space-between; padding:20px 30px;}
.freelancer-dashboard-page .ratings-section .block{display:flex; flex-direction:column;}
.freelancer-dashboard-page .ratings-section .block:not(:last-child){margin-right:20px;}
.freelancer-dashboard-page .ratings-section .block .num{font-size:31px;}
.freelancer-dashboard-page .ratings-section .block .num span{font-size:17px;}
.freelancer-dashboard-page .ratings-section .block.seller .num{color:#962C08;}
.freelancer-dashboard-page .ratings-section .block.buyer .num{color:#F07E22;}
.freelancer-dashboard-page .ratings-section .block .text{font-size:12px; font-weight:500;}

.freelancer-dashboard-page .section.links a{color:#003965; font-weight:500; padding:3px 8px; position:relative;}
.freelancer-dashboard-page .section.links a:not(:last-child)::after{background-color:#003965; content:''; display:block; position:absolute; height:14px; right:0px; top:7px; width:1px;}

.dashboard-page .left-block{background-color:#fff;}
.dashboard-page .block{padding:0px;}
.dashboard-page .block.white-block,
.dashboard-page .sm-block.white-block{border-radius:5px; padding:30px 25px; width:calc(50% - 10px);}
.dashboard-page .right-section .block.white-block{width:100%;}
.dashboard-page .right-section .block.white-block > div{width:100%;}
.dashboard-page .white-block{background-color:#fff;}
.dashboard-page .right-block{width:calc(100% - 340px);}
.dashboard-page .left-block{width:320px;}
.dashboard-page .block-heading{color:#000; font-size:18px; font-weight:bold; line-height:1.3; margin-bottom:15px;}
.dashboard-page .left-block .text-center a{color:#003965; font-weight:500; text-decoration:underline;}

.dashboard-page .right-block .left-section{width:320px;}
.dashboard-page .right-block .right-section{margin-right:20px; width:calc(100% - 360px);}
.dashboard-page .white-block.block{border-radius:5px; display:flex; flex-wrap:wrap; min-height:470px; justify-content:space-between;}
.dashboard-page .white-block.block:hover{box-shadow:0 0 10px #dadada;}
.dashboard-page .white-block.block .sm-block{margin-bottom:20px; width:42%}
.dashboard-page .w-35 .white-block.block .sm-block:nth-child(3n),
.dashboard-page .w-35 .white-block.block .sm-block:last-child,
.dashboard-page .w-18 .white-block.block .sm-block:last-child{margin-bottom:0px;}
.dashboard-page .w-18 .white-block.block .sm-block{width:100%}
.dashboard-page .white-block.block .sm-block .num{color:#000; font-size:17px; font-weight:600; margin-bottom:4px;}
.dashboard-page .white-block.block .sm-block .text{font-size:12px; line-height:1.3; text-transform:uppercase;}

.dashboard-page .ant-empty-normal{margin:90px 0;}

.dashboard-page .right-block .right-section.white-block.block{flex-direction:column;}
.dashboard-page .right-block .ant-tabs-tab{margin-right:12px; padding:0px;}
.dashboard-page .right-block .ant-tabs-tab a{color:#B5B5C3; padding:5px 15px; border-radius:4px; display:block; font-size:13px; font-weight:400; text-transform:uppercase;}
.dashboard-page .right-block .ant-tabs-tab-active a{background-color:#962C08; color:#fff;}
.dashboard-page .ant-tabs-ink-bar{display:none !important;}

.dashboard-page .ant-tabs-bar{border-bottom:0px none;}

.dashboard-page .right-block .full-section{width:100%;}

/* .dashboard-page .right-section .ant-tabs-content .ant-tabs-tab a{border:0px none; border-radius:0px; color:#000; margin-bottom:0px; padding:inherit; padding-bottom:10px;}
.dashboard-page .right-section .ant-tabs-content .ant-tabs-tab-active a{background-color:inherit; border-bottom:5px solid #f07e22; color:#f07e22;}
.dashboard-page .right-section .ant-tabs-content .ant-tabs-bar{border-bottom:1px solid #000;} */

.dashboard-page .ant-table-content{color:#000; font-size:12px;}
.dashboard-page .ant-table-content .ant-table-thead{text-transform:uppercase;}
.dashboard-page .ant-table-content .ant-table-thead > tr > th{border-bottom:0px none; padding:14px 25px;}
.dashboard-page .ant-table-content .ant-table-tbody > tr > td{padding:20px 25px;}
.dashboard-page .ant-table-content img{border-radius:50%; max-height:30px; max-width:30px; margin-right:5px;}
.dashboard-page .ant-table-content .btn-grey{border-radius:2px;}

.dashboard-page .ant-table-content tr > td:first-child{border-left:1px solid #e7e6e6;}
.dashboard-page .ant-table-content tr > td:last-child{border-right:1px solid #e7e6e6;}
.dashboard-page .ant-table-content tr:first-child > td{border-top:1px solid #e7e6e6;}
.dashboard-page .ant-table-content tr:last-child > td{border-bottom:1px solid #e7e6e6;}
.dashboard-page tr.ant-table-row td:last-child a{background-color:#F3F6F9; border-radius:2px; color:#003965; font-size:12px; font-weight:500; padding:5px 10px;}
.dashboard-page tr.ant-table-row:nth-child(2n) td:last-child a{background-color:#928A87; color:#fff;}

.dashboard-page .left-block .block-title{color:#003965; display:flex; justify-content:space-between; font-size:20px; font-weight:500; padding:18px 20px 10px;}
.dashboard-page .left-block .block-title a{font-size:14px; align-self:center; color:#ee7d22; font-weight:normal}

.dashboard-page .escrow-block{display:flex; justify-content:space-between;}
.dashboard-page .color-blocks{display:flex; flex-wrap:wrap; justify-content:space-between;}
.dashboard-page .color-blocks .sm-block{border-radius:5px; color:#fff; margin-top:20px; padding:20px; width:calc(50% - 10px);}
.dashboard-page .color-blocks .sm-block:nth-child(1),
.dashboard-page .color-blocks .sm-block:nth-child(2){margin-top:0px;}
.dashboard-page .color-blocks .sm-block:nth-child(4n+1){background-color:#928A87;}
.dashboard-page .color-blocks .sm-block:nth-child(4n+2){background-color:#003965;}
.dashboard-page .color-blocks .sm-block:nth-child(4n+3){background-color:#A24921;}
.dashboard-page .color-blocks .sm-block:nth-child(4n+4){background-color:#6E939C;}
.dashboard-page .color-blocks .sm-block .num,
.dashboard-page .sm-block .num{font-size:22px; font-weight:500;}
.dashboard-page .color-blocks .sm-block .text,
.dashboard-page .sm-block .text{font-size:15px;}
.dashboard-page .sm-block.white-block .num{color:#234352;}
.dashboard-page .sm-block.white-block:nth-child(2n) .num{color:#962C08;}
.dashboard-page .sm-block.white-block .text{color:#B5B5C3;}

.dashboard-page .messages-list{color:#000; font-size:14px; padding:0px ;list-style-type:none;}
.dashboard-page .messages-list > li{border-left:5px solid transparent; display:flex; justify-content:flex-start; padding:10px 15px;}
.dashboard-page .messages-list > li:hover{background-color:#fafafa; border-color:#f07e22;}
.dashboard-page .messages-list li .img-block{align-self:center; border-radius:50%; height:45px; margin-right:5px; width:45px; position:relative; text-align:center; display:flex;}
.dashboard-page .messages-list li .img-block img{margin:0 auto;}
.dashboard-page .messages-list li .img-block .green{background-color:#10f582; position:absolute; bottom:0px; right:5px; width:8px; height:8px; border-radius:50%;}
.dashboard-page .messages-list li > .text{width:calc(100% - 50px);}
.dashboard-page .messages-list li .name{display:flex; font-weight:600; justify-content:space-between; margin-bottom:2px;}
.dashboard-page .messages-list li .time{color:#aba8a8; font-size:10px; font-weight:normal;}
.dashboard-page .messages-list li .msg{color:#aba8a8; display:flex; justify-content:space-between; margin-top:2px;}
.dashboard-page .messages-list li .msg .num{background-color:#ff98e2; color:#fff; padding:2px 5px; border-radius:3px;}

.dashboard-page div.ant-typography{margin-bottom:5px;}
.dashboard-page .details-block{display:flex; justify-content:space-between;}
.dashboard-page .details-block .block-heading{border:0px none; background-color:transparent; color:#003965; padding:0px; margin:20px 0; font-weight:500; font-size:20px;}

.dashboard-page .user-switch{color:#003965; font-size:25px; font-weight:500;}
.dashboard-page .user-switch > span:first-child{margin-right:10px;}
.dashboard-page .user-switch > span:last-child{color:#B5B5C3; margin-left:10px;}


.products_offers{display:flex; flex-wrap:wrap; padding-right:20px;}
.products_offers .single-offer{background-color:#fff; border:1px solid #D5D5D5; border-radius:5px; box-shadow:none; overflow:hidden; padding:30px; position:relative;}
.products_offers > .single-offer{margin-right:15px; width:calc(33.33% - 10px);}
.products_offers > .single-offer:nth-child(3n){margin-right:0px;}
.products_offers .top-block{border-radius:2px; display:flex; justify-content:center; flex-direction:column; height:157px; overflow:hidden;}
.products_offers .top-block img{border:0px none; width:100%;}
.products_offers .featured-text{background-color:#962C08; color:#fff; font-size:10px; font-weight:500; padding:3px 25px; position:absolute; top:13px; left:-20px; transform:rotate(-45deg);}
.products_offers .wishlist-count{background-color:#F3F6F9; color:#003965; font-weight:500; padding:3px 8px; position:absolute; top:30px; border-radius:5px 0 0 5px; right:30px;}
.products_offers .title{color:#003965; display:block; font-size:17px; font-weight:500; line-height:1.2; margin:10px 0 5px;}
.products_offers .last-activity{color:#6E939C;}
.products_offers .slick-slide > div{margin:0 15px;}
.products_offers .ratings-price,
.products_offers .ratings{display:flex;}
.products_offers .ratings-price{justify-content:space-between;}
.products_offers .rating-text{color:#003965; align-self:center; margin-left:5px;}
.products_offers .price-text{color:#003965; align-self:center; margin-left:5px; font-weight:500;}
.products_offers .price-text .text{color:#6E939C; font-weight:normal; margin-right:3px;} 
.products_offers .single-offer .bottom-block{display:flex; justify-content:space-between; margin-top:20px;}
.products_offers .single-offer .sold-text{color:#6E939C; display:flex; flex-direction:column; line-height:1;}
.products_offers .single-offer .sold-text .num{color:#003965; font-size:18px; font-weight:500;}
.products_offers .single-offer .ant-select{border:1px solid #F07E22; border-radius:5px; overflow:hidden;}
.products_offers .single-offer .ant-select-selector{background-color:transparent; border:0px none; min-height:auto;}
.products_offers .single-offer .ant-select-selection-placeholder{color:#F07E22; font-size:15px;font-weight:500;}
.products_offers .single-offer .ant-select-arrow{color:#F07E22;}
/*      Dashboard End       */

/*      Products Listing Start      */
.listing.products_offers{padding-right:0px;}
.listing.products_offers .ant-list-items{display:flex; flex-wrap:wrap;}
.listing.products_offers .ant-list-items .single-offer{margin-bottom:40px; margin-right:40px; width:calc(50% - 20px);}
.listing.products_offers .ant-list-items .single-offer:nth-child(2n){margin-right:0px;}

.listing-page .listing.products_offers .single-offer .ant-select-selector{height:auto !important;}
.listing-page .listing.products_offers .single-offer .ant-select-selection-placeholder{line-height:38px !important;}
/*      Products Listing End      */

/*      Product View Start      */
.view-details-left-blk .top-section{overflow:hidden; position:relative;}
.tag-wrapper{background-color:#962c08; color:#fff; font-size:10px; font-weight:500; left:-28px; padding:5px 32px; position:absolute; top:12px; transform:rotate(-45deg); z-index:9999;}

.product-image{border:1px solid #f4f4f4; display:flex; height:400px; justify-content:center; margin-bottom:15px;}
.product-gallery{display:flex; flex-wrap:wrap;}
.product-gallery .g-img{align-items:center; border:1px solid #f4f4f4; cursor:pointer; display:flex; flex-direction:column; justify-content:center; margin-right:15px; width:calc(16.66% - 12.5px);}
.product-gallery .g-img:nth-child(6n){margin-right:0px;}

.blue-tabs{}
.blue-tabs{color:#6E939C;}
.blue-tabs .ant-tabs-nav .ant-tabs-tab{border-radius:5px; color:#6E939C; font-size:13px; margin-right:0px; padding:5px 20px; text-transform:uppercase;}
.blue-tabs .ant-tabs-bar{border-bottom:0px none;}
.blue-tabs .ant-tabs-nav .ant-tabs-tab-active{background-color:#003965; }
.blue-tabs .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn{color:#fff;}
.blue-tabs .ant-tabs-ink-bar-animated{display:none !important;}
.blue-tabs .ant-tabs-tabpane{background-color:#fff; padding:30px;}
.blue-tabs .ant-tabs-nav-wrap{justify-content:flex-start !important;}
.view-details-left-blk .ant-tabs .title{color:#003965; font-size:20px; font-weight:500;}
.view-details-left-blk .ant-tabs .text{margin-bottom:15px;}
.view-details-left-blk .ant-tabs .clarification-chat .text{margin-bottom:0px;}
.view-details-left-blk .location{color:#003965; font-size:12px;}
.clarification-chat .ratings-reviews{align-items:flex-end; display:flex;}
.clarification-chat .rating-text{color:#003965; margin-left:5px;}

.addons-section .addons .block{border:1px solid #e3e3e3; border-radius:5px; margin-bottom:15px; padding:28px 30px;}
.addons-section .addons .block .top,
.addons-section .addons .block .bottom{display:flex; justify-content:space-between;}
.addons-section .top .heading{font-size:16px; font-weight:500;}
.view-details-left-blk .addons-section .text{margin-bottom:0px;}
.addons-section .price{color:#003965; font-size:24px; font-weight:700;}
.addons-section .ant-checkbox .ant-checkbox-inner{height:24px; width:24px;}
.addons-section .ant-checkbox .ant-checkbox-inner::after{height:12px; left:25%; width:7px;}

.buy-blk{color:#003965; display:flex; flex-direction:column; font-size:25px; margin-top:28px; padding-top:28px; position:relative; width:100%;}
.buy-blk::before{border-top:1px solid #eaeaea; content:''; display:block; left:-50px; position:absolute; top:0px; width:calc(100% + 100px);}
.buy-blk .price{font-weight:700;}
.buy-blk .in-de-blk{display:flex;}
.buy-blk .in-de-blk label{margin-right:5px;}
.buy-blk .in-de-blk .ant-btn{border-radius:0px; color:#003965; height:34px; line-height:0.8; padding:0px; width:26px;}
.buy-blk input{background-color:transparent; border-left:0px none; border-right:0px none; color:#003965; font-size:25px; height:34px; text-align:center; width:46px;}
.buy-blk > .btn-blue{align-self:center; border:0px none; border-radius:5px; font-size:20px; margin:30px 0; width:160px;}

.response-block{border:1px solid #d9d9d9; border-radius:5px; color:#6e939c; margin-bottom:30px; padding:10px; text-align:center; width:100%;}
.response-block .ico{margin-bottom:4px;}
.response-block .blue-txt{color:#003965;}

.money-protection{align-items:center; background-color:#fff; display:flex; justify-content:space-between; margin-bottom:20px; padding:24px;}
.money-protection .details{width:calc(100% - 80px);}
.money-protection .title{color:#003965; font-size:18px; font-weight:500;}
.money-protection .text{color:#6e939c;}

.blue-tabs ol{color:#6e939c; padding-left:10px;}
.blue-tabs li::marker{color:#003965; font-weight:600;}
/*      Product View End        */

/*      Product Add Start       */
.blue-gr-bg{background-image:linear-gradient(to bottom, #d7e8f4, #e3edf8, #eff2fa, #f8f8fd, #ffffff); z-index:1;}
.blue-gr-bg .heading-container{margin-top:-180px; position:relative; z-index:1;}
.product-add ol{padding-left:15px;}
.product-add .ant-collapse{background-color:transparent; border:0px none;}
.product-add .ant-collapse .ant-collapse-header{background-color:#FFF1E5; color:#962C08 !important; font-size:16px; font-weight:700; padding:12px 30px !important;}
.product-add .ant-collapse-header .anticon{border:2px solid #962C08; border-radius:50%; -o-border-radius:50%; -moz-border-radius:50%; -webkit-border-radius:50%; padding:5px;}
.product-add .ant-collapse-header .anticon svg{height:15px; width:15px;}
.product-add .ant-collapse > .ant-collapse-item{border:0px none; box-shadow:0 0 27px rgba(77,77,77,0.16); margin-bottom:20px;}

.product-add .ant-collapse-content-box{color:#6E939C; line-height:2; padding:30px;}
.product-add .ant-collapse-content-box ul,
.product-add .ant-collapse-content-box ol{margin-bottom:0px;}
.product-add .ant-collapse-content-box li{margin-bottom:10px;}
.product-add .ant-collapse-content-box a{color:#962C08;}
/*    Product Add End        */

/*      Project View Start      */
.white-block{background-color:#fff; padding:40px;}
.product-add .white-block{box-shadow:0 0 27px rgba(77,77,77,0.16);}
.frm-white-block{}
.frm-white-block .ant-input,
.frm-white-block .ant-input-number-input{height:47px;}
.frm-white-block .ant-input{border:0px none;}
.frm-white-block .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{height:40px; padding:5px 15px;}
.frm-white-block .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector{background-color:#f8f8f8; border:0px none; min-height:40px; padding:5px 15px;}
.frm-white-block .d-flex .w-20{width:18%;}
.frm-white-block .d-flex .w-60{width:58%;}

.frm-white-block .last .ant-row{width:calc(100% - 43px);}
.frm-white-block .last input{border-bottom-right-radius:0px; border-top-right-radius:0px;}
.remove-addon,
.clear-total{align-self:flex-start; border:0px none; border-bottom-left-radius:0px; border-top-left-radius:0px; height:47px; padding:12px 15px; width:43px;}
.extra-addons > div:first-child .remove-addon{margin-top:40px;}
.btn-lgreen{background-color:#6E939C; color:#fff;}
.btn-lgreen:hover,
.btn-lgreen:active,
.btn-lgreen:focus{background-color:rgba(110,147,156,.7); color:#fff;}
.no-label .ant-form-item-label{display:none;}

.add-addon-block{margin-bottom:20px; text-align:right;}
form .note.left{text-align:left;}

.orng-checkbox{}
.orng-checkbox .ant-checkbox-inner{height:23px; width:23px;}
.orng-checkbox .ant-checkbox-checked .ant-checkbox-inner{background-color:#F07E22; border-color:#F07E22;}
.orng-checkbox .ant-checkbox-checked .ant-checkbox-inner::after{height:14px; top:40%; width:7px;}

.frm-white-block .note,
.frm-white-block .orng-checkbox{margin-top:10px;}
.frm-white-block .ant-checkbox + span{align-self:center; color:#adaaaa; font-size:16px; font-weight:400;}

.frm-white-block label.upload-panel{background-color:#F8F8F8; display:flex; align-items:center; padding:30px; cursor:pointer; transition:.2s all ease-in-out}
.frm-white-block label.upload-panel:hover{background-color:rgba(115, 3, 192, .03); border-color:rgba(115, 3, 192, .3)}
.frm-white-block label.upload-panel .btn{border:0px none; border-radius:5px; -o-border-radius:5px; -moz-border-radius:5px; -webkit-border-radius:5px; font-weight:400; margin-right:20px; text-transform: uppercase;}
/* .frm-white-block label.upload-panel .icon{font-size:50px; color:#ee7d22} */
.frm-white-block label.upload-panel p{margin:0; font-weight:normal; text-transform:capitalize; color:rgba(0,0,0,.5);}

.frm-white-block .form-gallery ul li{flex-direction:column; height:200px; width:200px;}
.frm-white-block .form-gallery .img-block{max-height:calc(100% - 40px); max-width:100%; overflow:hidden; text-align:center;}
.frm-white-block .form-gallery .text{font-size:12px; max-width:100%; position:relative; word-wrap:break-word;}
.frm-white-block .form-gallery .name{max-width:calc(100% - 20px);}
.frm-white-block .form-gallery ul li .close{background-color:transparent; opacity:1;}
.frm-white-block .form-gallery .close svg{color:#6E939C;}

.frm-white-block label.ant-radio-wrapper{color:#6E939C !important; margin-bottom:10px;}
.frm-white-block label.ant-radio-wrapper.ant-radio-wrapper-checked{color:#003965 !important;}
form .ant-radio-wrapper .ant-radio-inner::after{background-color:#F07E22;left:3.5px;}
form .ant-radio-wrapper:first-child .ant-radio-inner::after{top:3px;}

.frm-white-block .ant-radio-checked::after{border:1px solid #F07E22;}
.frm-white-block .ant-radio-checked .ant-radio-inner{border-color:#F07E22;}
.link{color:#962C08; font-weight:400;}

.project-details .left-block,
.project-details .right-block{background-color:#fff;}
.project-details .left-block{width:calc(100% - 330px);}
.project-details .right-block{width:320px;}

.project-details .name-block{background-color:#ee7d22; color:#ffff; font-size:18px; padding:25px 45px;}
.project-details .name-block .name{font-size:28px; font-weight:bold;}
.project-details .timeline-block{padding:25px 30px; height:500px; overflow-y:auto;}
.proposal-block{display:flex; justify-content:space-between; margin-bottom:20px;}
.proposal-block .icon{border-radius:50%; background-color:#f3e2ff; display:flex; flex-direction:column; justify-content:center; height:45px; overflow:hidden; text-align:center; width:45px;}
.proposal-block .icon img{}
.proposal-block .icon svg{color:#ee7d22; height:25px; margin:0 auto; width:25px;}
.proposal-block > .details{color:#5e5e5e; width:calc(100% - 110px); font-size:15px; margin-bottom:30px; position:relative; overflow:hidden;}
.proposal-block:not(.right) > .details{margin-right:55px;}
.proposal-block.right > .details{margin-left:55px;}

.proposal-block > .details .top{padding:15px 20px}
.proposal-block > .details .top .title{font-weight:600;}
.proposal-block.chat > .details .top{background-color:#f3e2ff;}
.proposal-block.alert-block > .details .title{color:#4d4d4d; font-size:20px; font-weight:600; margin-bottom:5px;}
.proposal-block.alert-block > .details .text{margin-bottom:20px;}
.proposal-block > .details .images{display:flex; flex-wrap:wrap; justify-content:space-between; margin-bottom:20px;}
.proposal-block > .details .images img{border:1px solid #d9d9d9; width:23%;}
.proposal-block.alert-block > .details .details-block{border:1px solid #f2f2f2; border-radius:10px; }

.proposal-block .details-block{border:1px solid #eee; border-radius:10px;}

.proposal-block.feedback .user-details{display:flex; margin-top:15px; align-items:center}
.proposal-block.feedback .user-details .ratings-block{}
.proposal-block.feedback .user-details .ratings-block .rating{display:flex; flex-direction:row; justify-content:flex-start}
.proposal-block.feedback .user-details .ratings-block .rating .top-text{width:200px}
.proposal-block.feedback .user-details .img-block{margin-right:20px;}
.proposal-block.feedback .user-details .ratings span{}
.proposal-block.feedback .user-details .ratings span.ico{color:#ff4500; margin:0 5px;}

.proposal-items-block > div{font-size:12px; font-weight:normal;}
.proposal-items-block .title{background-color:#f3e2ff; color:#ee7d22; display:flex; justify-content:space-between; text-transform:uppercase; font-size:12px; font-weight:inherit; margin-bottom:0px;}
.proposal-items-block > div span{align-self:center; padding:15px 30px;}
.proposal-items-block .detail-block{border-bottom:1px solid #c6c6c6; display:flex; justify-content:space-between;}
.project-details .timeline-block .price-block{display:flex; justify-content:space-between;}
.project-details .timeline-block .price-block .left{padding:6px 30px; width:60%;}
.project-details .timeline-block .price-block .left .price-detail-block{display:flex; justify-content:space-between;}
.project-details .timeline-block .price-block .left .price-detail-block span{color:#000; font-weight:600; min-width:30%; padding:7px 0px; text-align:left;}
.project-details .timeline-block .price-block .left .price-detail-block span:first-child{color:#ee7d22;}
.project-details .timeline-block .price-block .right{background-color:#969696; color:#fff; display:flex; flex-direction:column; justify-content:center; font-size:20px; font-weight:600; text-align:center; width:40%;}
.project-details .timeline-block .price-block .right .text{display:flex; flex-direction:column; justify-content:center; margin-bottom:0px; padding:10px 20px; text-align:center; width:100%;}
.project-details .timeline-block .price-block .right .text svg{height:25px; width:25px;}
.project-details .timeline-block .time-block{margin-top:5px; font-size:10px; color:#adaaaa;}

.proposal-block.alert-block > .icon{background-color:transparent;}
.proposal-block.alert-block > .icon svg{color:#ff4500; height:100%; width:100%;}
.proposal-block.alert-block > .details{border-color:#ff4500;}
.proposal-block.alert-block > .details .top{display:flex; justify-content:space-between; padding:25px;}
.proposal-block.alert-block  > .details .img-block{display:flex; flex-direction:column; justify-content:center;}
.proposal-block.alert-block  > .details .title{color:#ff4500;}
.proposal-block.alert-block > .details .text{margin-bottom:0px;}
.btn.btn-orange{background-color:#ff4500; border:1px solid #ff4500; color:#fff;}
.btn.btn-orange:hover{background-color:#fff; color:#ff4500; border:1p solid #ff4500;}
.proposal-block.alert-block .btn-orange{align-self:center; font-size:18px; font-weight:normal; padding:8px 25px;}

.proposal-block.alert-block .download-block{background-color:#fdeff9; padding:10px 30px; align-items:center; display:flex;}
.proposal-block.alert-block .download-block svg{color:#000;}
.proposal-block.alert-block .download-block span{color:#ec38bc; padding:0px !important;}

.proposal-block.alert-block.chat .details{}
.proposal-block.alert-block .details .text.lg{font-size:17px; color:#000; margin-bottom:0px;}
.proposal-block.alert-block.right.chat .details{ border:0px none;}
.proposal-block.alert-block.right.chat .details .time-block{left:auto; right:0px;}

.proposal-block.not-round .details{border-radius:0px;}
.proposal-block > .details-block .single-block{display:flex; justify-content:space-between;}
.proposal-block > .details-block .single-block span{padding:9px 30px;}
.proposal-block > .details-block .item-block span,
.proposal-block > .details-block .single-block span{text-align:center; width:30%;}
.proposal-block > .details-block .item-block span:first-child,
.proposal-block > .details-block .single-block > span:first-child{text-align:left;}
.proposal-block > .details-block .single-block span.text{background-color:#969696; color:#fff; display:flex; flex-direction:column; justify-content:center; font-size:20px; font-weight:600; margin-bottom:0px; text-align:center;}
.proposal-block > .details-block .single-block span.text span{width:100%;}

.proposal-block.invoice .details{background-color:#f3e2ff; border:0px none;}
.proposal-block.invoice .details .title{color:#414141; margin-bottom:10px;}
.proposal-block.invoice .user-details{display:flex; font-size:9px;}
.proposal-block.invoice .user-details .img-block{border-radius:50%; height:45px; margin-right:10px; overflow:hidden; text-align:center; width:45px;}
.project-details .timeline-block .user-details .rating{display:flex; justify-content:center; flex-direction:column;}
.proposal-block.invoice .user-details .rate{font-size:11px; font-weight:bold; margin-right:4px;}
.proposal-block.invoice .user-details svg{color:#ff4500; height:12px; width:12px;}
.project-details .timeline-block .user-details .top-text{display:flex; margin-bottom:3px;}
.project-details .timeline-block .line-block{display:flex; justify-content:center; flex-direction:column; width:120px;}
.project-details .timeline-block .line{background-color:#fff; height:4px; width:100%;}
.project-details .timeline-block .hover-line{background-color:#ff4500; height:4px; margin-top:-4px; width:60%;}

.proposal-overview .proposal-block:not(.right) > .details{margin-right:20px}
.proposal-overview .proposal-block .icon{width:60px; height:60px}
.proposal-overview .proposal-block .details-block{border:0 none; padding:0; display:flex}
.proposal-overview .proposal-block .details-block .item-block{border:1px solid #eee; border-radius:10px;}
.proposal-overview .proposal-block .details-block .title{border-radius:10px 10px 0 0}
.proposal-overview .proposal-block > .details .top{padding:0}
.proposal-overview .proposal-details{margin-top:40px}
.proposal-overview .proposal-details .top .title{font-weight:bold; font-size:20px}
.proposal-overview .proposal-user-details{padding-top:10px;}
.proposal-overview .proposal-user-details h5{font-weight:600; font-size:20px; margin:0; line-height:1;}
.proposal-overview .proposal-user-details strong{font-weight:normal; font-size:13px}

.project-details .ant-layout-content .block-heading{background-color:#7303c0; color:#fff; display:flex; justify-content:space-between; padding:15px 30px;}
.project-details .ant-layout-content .block-heading svg{height:24px; width:24px;}
.project-details .block-content{font-size:15px; padding:30px;}

.project-details form{padding:0 30px;}

.send-message-block{background-color:#f5f5f5; padding:0 30px 0 60px; margin-bottom:0px;}
.project-details .send-message-block form{padding:0px;}
.send-message-block .ant-tabs-tab{margin:0px; padding:0}
.send-message-block .ant-tabs-tab-active{background-color:#fff;}
.send-message-block .ant-tabs-tab a{color:#000; font-size:12px; align-items:center; display:flex; padding:0 10px;}
.send-message-block .ant-tabs-tab-active a{border-bottom:0px none;}
.send-message-block .ant-tabs-tab a svg{height:15px; width:15px;}
.send-message-block .ant-tabs-ink-bar{display:none !important;}

.project-details .milestone-form-block .btn.add-milestone-block{border-radius:50%; padding:0px 6px; height:20px; width:20px;}
.milestone-form-block .heading-block label{color:#414141; font-size:16px; padding:10px 0; width:50%;}
.milestone-form-block form,
.invoice-form-block form{padding-top:20px;}

.project-details .new-invoice-block,
.project-details .nda-block{margin-top:45px;}

.project-details .nda-block{background-color:#f7f7f7;}
.project-details .nda-block .block-content{line-height:1.5;}
.project-details .nda-block .block-content .text{color:#000;}
.project-details .nda-block form{padding-left:70px;}
.project-details .nda-block form > .form-item-block > .ant-form-item:first-child{width:85%; margin-right:5%;}
.project-details .nda-block form > .form-item-block label,
.project-details .send-message-block form > .form-item-block label{font-size:15px; line-height:1.5; text-transform:inherit; color:#707070;}
.project-details .milestone-form-block form > .form-item-block .btn.btn-pink-bg,
.project-details .nda-block form > .form-item-block .btn,
.project-details .send-message-block form > .form-item-block .btn{padding:8px 30px; font-weight:normal; border-radius:5px;}

.invoice-form-block{background-color:#fff; padding:25px;}
.invoice-form-block .form-item-block .w-15{width:13%;}
.invoice-form-block .form-item-block .w-20{width:18%;}
.invoice-form-block .form-item-block .w-30{width:28%;}

.invoice-form-block .form-item-block:not(:last-child) label{color:#414141; font-size:16px; margin-bottom:10px;}
.invoice-form-block .form-item-block .ant-checkbox-wrapper{text-transform:inherit;}
.invoice-form-block form > .form-item-block .btn{font-weight:normal; border-radius:5px; padding:8px 30px;}
.invoice-form-block .btn.add-invoice-block{border-radius:50% !important; padding:0px 6px !important; height:20px; width:20px;}

.ant-progress-line{line-height:1;}

.client-details-block{font-size:12px; padding:25px 60px;}
.client-details-block .item{display:flex; justify-content:space-between; margin-bottom:15px;}
.client-details-block .item.top--item{justify-content:flex-start;}
.client-details-block .item.top--item .details{margin-left:15px;}
.client-details-block .item .text{font-weight:600;}
.client-details-block .img-block{border-radius:50%; height:50px; overflow:hidden; width:50px;}
.client-details-block .name{color:#414141; font-size:15px; font-weight:600; margin-bottom:4px;}
.project-details .right-block .ant-menu{height:auto;}

.sidebar-tabs-block{max-height:530px; overflow-y:auto; padding-top:25px;}

.client-details-block .details{display:flex; justify-content:center; flex-direction:column;}
.client-details-block .rating{display:flex;}
.client-details-block .rating .ratings span{font-size:15px;}
.client-details-block .rating .ratings span.ico{margin-left:5px; color:#ff4500}
.client-details-block .rating .top-text{display:flex; flex-direction:column; margin-left:10px}
.client-details-block .rating .top-text .text{display:flex; font-weight:600;}
.client-details-block .rating .top-text svg{color:#ff4500; height:14px;margin-left:4px; width:14px;}
.project-details .right-block .line-block{display:flex; justify-content:center; flex-direction:column; width:80px;}
.project-details .right-block .line{background-color:#e6e6e6; height:4px; width:100%;}
.project-details .right-block .hover-line{background-color:#ff4500; height:4px; margin-top:-4px; width:60%;}

.project-details .right-block .ant-tabs-bar{margin-bottom:0px;}
.project-details .right-block .ant-tabs .ant-tabs-nav{text-align:Center; width:100%;}
.project-details .right-block .ant-tabs .ant-tabs-tab{background-color:#fff;margin-right:0px; padding:0px; width:50%;}
.project-details .right-block .ant-tabs .ant-tabs-tab a{border-bottom:0px none; color:#000; font-size:13px; display:block; padding:15px 5px; width:100%;}
.project-details .right-block .ant-tabs .ant-tabs-tab-active a{background-color:#f5f5f5;}
.project-details .right-block .ant-tabs .icon{margin-right:10px;}
.project-details .right-block .ant-tabs .icon svg{width:25px;}
.project-details .right-block .ant-tabs-ink-bar{display:none !important;}
/* .project-details .right-block ul{border-top:1px solid #efefef; padding:0px;}
.project-details .right-block ul li{border-bottom:1px solid #efefef; display:flex; padding:15px 30px;}
.project-details .right-block ul li .text.d-flex{justify-content:space-between; width:100%;} */

.project-details .right-block .workstream-list{color:#000; font-weight:600; font-size:13px;}
.project-details .right-block .workstream-list .name{font-size:11px; font-weight:400;}

.sidebar-tabs-block .proposal-block > .details{margin-right:0; flex:1 1; margin-bottom:0}
.sidebar-tabs-block .proposal-block .icon{background-color:transparent;}
.sidebar-tabs-block .proposal-block .icon .feather{color:rgba(0, 0, 0, 0.3)}
.sidebar-tabs-block .proposal-block > .details .top{padding:0}
.sidebar-tabs-block .proposal-block > .details .top .title{font-size:12px; font-weight:600; color:#000; cursor:pointer}
.sidebar-tabs-block .proposal-block > .details .top .time-block{font-size:10px;}
.sidebar-tabs-block .proposal-block > .details .top .ant-typography{font-size:11px; color:#717171}
.sidebar-tabs-block .proposal-block .details-block{border:0 none}

.sidebar-tabs-block .proposal-items-block .title{border-radius:10px 10px 0 0; border-left:1px solid #DDDDDD; border-top:1px solid #DDDDDD; border-right:1px solid #DDDDDD}
.sidebar-tabs-block .proposal-items-block{margin-bottom:20px}
.sidebar-tabs-block .proposal-items-block .details{border-left:1px solid #DDDDDD; border-right:1px solid #DDDDDD}
.sidebar-tabs-block .proposal-items-block .price-block{border-left:1px solid #DDDDDD; border-right:1px solid #DDDDDD; border-bottom:1px solid #DDDDDD; display:flex; flex-direction:column;}
.sidebar-tabs-block .proposal-items-block .price-block .left{width:100%}
.sidebar-tabs-block .proposal-items-block .price-block .price-detail-block{display:flex; justify-content:space-between;}
.sidebar-tabs-block .proposal-items-block > div span{padding:15px 10px}
.sidebar-tabs-block .proposal-block-container{padding:15px 15px; border-bottom:1px solid #ddd;}
.sidebar-tabs-block .proposal-block-container .proposal-block{margin-bottom:0}

.sidebar-tabs-block .proposal-items-block .price-block .right{display:flex; justify-content:center; align-items:center}
.sidebar-tabs-block .proposal-items-block .price-block .right .workflow-action-dropdown{margin-bottom:15px; margin-top:15px; padding:5px 10px}
.sidebar-tabs-block .proposal-items-block > div .workflow-action-dropdown span{padding:5px; font-size:12px !important;}

.workstream-block{display:flex; font-size:10px; padding:20px 25px;}
.workstream-block .icon{margin-top:10px;}
.workstream-block .date{}
.workstream-block .title{color:#000; font-size:12px; font-weight:600;}
.workstream-block .price{color:#000; font-size:12px; margin-bottom:10px;}
.workstream-block .text{color:#717171; font-size:11px;}

.project-details .right-block .card{border-radius:10px; border:1px solid #f2f2f2; font-size:12px; overflow:hidden; margin:0 10px 20px;}
.project-details .right-block .head{background-color:#f3e2ff; color:#7303c0; padding:10px 15px; text-transform:uppercase;}
.project-details .right-block .mid{border-bottom:1px solid #efefef; padding:10px 15px;}
.project-details .right-block .bottom{padding:15px;}
.project-details .right-block .bottom >div{padding:6px 0; font-weight:600;}
.project-details .right-block .bottom span:first-child{color:#7303c0;}
.project-details .right-block .bottom span:last-child{color:#000;}
.project-details .right-block .ant-tabs .btn.btn-grey{background-color:#707070; margin-right:10px; margin-bottom:20px; font-size:18px; padding:6px 30px;}
.project-details .right-block .ant-tabs .btn.btn-grey svg{margin-left:5px;}

.project-details .ant-menu-submenu{background-color:#f3e2ff;}
.project-details .ant-menu-submenu-title{color:#7303c0; font-size:12px; font-weight:600;}

.project-details .invoices-list{border-top:1px solid #efefef; padding:0px;}
.project-details .invoices-list li{border-bottom:1px solid #efefef; display:flex; padding:15px 30px;}
.project-details .invoices-list li .icon{display:flex; justify-content:center; flex-direction:column;}
.project-details .invoices-list li svg{color:#000; height:24px; width:24px; margin-right:10px;}
.project-details .invoices-list li .text{color:#000; font-size:12px;}
.project-details .invoices-list li .text .date{color:#8f8f8f; font-size:11px;}
.project-details .invoices-list li .text .title{font-weight:600;}
/*      Project View End      */

/*      Messages Start      */
.main-content.account-bg{background-color:#fafafa; background-image:none;}
.messages-wrapper{width:100%}
.sidebar-col{background-color:#fff; height:calc(100% - 90px)}
.sidebar-col-section{background-color:#fff; margin-bottom:3px; padding:15px}
.sidebar-col-section.main{border-top:3px solid #fafafa}

.messages-wrapper .sidebar-col-section.main{padding:15px 0}

.messages-body{background-color:#fff; padding:15px 0 15px 15px; margin-left:5px; margin-right:5px; min-height:500px}

.user-messages-sidebar-list{}
.user-messages-sidebar-list .title{display:flex; flex-direction:column; font-size:12px;}
.user-messages-sidebar-list .title:hover{color:#ee7d22}
.user-messages-sidebar-list .title strong{margin-top:3px}
.user-messages-sidebar-list .title span{}
.user-messages-sidebar-list .ant-list-item{padding:10px 15px}
.user-messages-sidebar-list .ant-list-item:hover{background-color:rgba(115, 3, 192, .04);}
.user-messages-sidebar-list .ant-list-item.active{background-color:rgba(115, 3, 192, .06);}
.user-messages-sidebar-list .ant-list-item.active .title{color:#ee7d22}

.user-messages-list .block.chat{display:flex}
.user-messages-list .block.chat.right{justify-content:flex-start; align-items:flex-start; flex-direction:row-reverse;}
.user-messages-list .block.chat .icon{margin-right:15px;}
.user-messages-list .block.chat .details{flex:1 1; margin-right:65px; color:#000}
.user-messages-list .block.chat .details .top{border:1px solid #DDDDDD; background-color:#FAFAFA; border-radius:10px; padding:10px;}

.user-messages-list .block.chat.right .details{margin-right:0; margin-left:65px; text-align:right}
.user-messages-list .block.chat.right .icon{margin-left:15px; margin-right:0}
.user-messages-list .block.chat.right .details .top{background-color:transparent; border:1px solid rgba(0,0,0,.1)}

.user-messages-list .message-row{border-bottom:0 none}

.sidebar-filter-dropdown-wrapper{height:90px}
.sidebar-filter-dropdown{display:flex; justify-content:center; align-items:center; border:1px solid #191919; border-radius:30px; padding:7px; background-color:#fff; font-size:14px; font-weight:600; width:200px}
.sidebar-filter-dropdown strong{margin-left:10px}

.message-footer{height:90px; padding:0px 30px; display:flex; justify-content:center; align-items:center}
.message-input{display:flex; justify-content:space-between; align-items:center; margin-left:20px; flex:1 1}
.message-input textarea{border:1px solid #ccc; padding:10px; width:100%}
.message-action .ant-btn{background-color:#f07e22; border:0px none; border-radius:5px; color:#fff; font-size:13px; font-weight:500; height:auto; margin:0 0 0 20px; padding:11px 30px; }

.empty-lg{margin-top:100px;}
.empty-lg .ant-empty-image{height:auto; margin-bottom:30px}
.empty-lg .empty-icon{font-size:150px; color:rgba(0,0,0,.02);}
.empty-lg .ant-empty-description{font-size:15px; color:rgba(0,0,0,.3)}
/*      Messages End       */

/*      Buyer Pages Start     */
.account-bg .page-heading{margin-bottom:20px;}
.ant-tabs-ink-bar{background-color:#f07e22;}
.manage-projects .ant-layout{background-color:#fafafa}
.page-main-content{margin-top:45px;}
.manage-projects .page-heading{font-size:25px; font-weight:bold; padding-left:10px;}

.ant-tabs-nav .ant-tabs-tab a{color:#000; font-size:16px; padding-bottom:10px; position:relative;}
.ant-tabs-nav .ant-tabs-tab-active a{border-bottom:2px solid #f07e22; color:#f07e22;}
.ant-tabs-nav .ant-tabs-tab a .count{display:none;}
.ant-tabs-nav .ant-tabs-tab-active a .count{background-color:#f07e22; color:#fff; display:block; position:absolute; right:-14px; top:-9px; border-radius:50%; width:16px; height:16px; font-size:10px; font-weight:bold; text-align:center;}

.ant-tabs-left{display:flex; justify-content:space-between;}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-btn{text-shadow:none !important;}
.manage-projects .ant-tabs-ink-bar{display:none !important;}
.ant-tabs-left-bar,
.ant-tabs .ant-tabs-left-content{background-color:#fff; border:0px none; width:calc(100% - 270px);}
.ant-tabs .ant-tabs-left-content{border-left:5px solid #fafafa}
.ant-tabs .ant-tabs-left-bar{float:none; padding:20px 0; width:260px;}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab{padding:10px 35px; text-align:left;}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab a{color:#969696; font-size:16px;}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-active a{border-bottom:0px none; color:#000;}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab a svg{margin-right:10px;}

.page-section-heading{margin-top:25px; margin-bottom:35px;}
.tab-heading{color:#000; font-size:26px; font-weight:bold; margin-bottom:10px;}
.tab-sub-heading{color:#707070; font-size:20px; font-weight:normal; line-height:1;}

.manage-projects .ant-tabs .ant-tabs-left-bar{border:0px none;}
.manage-projects .ant-tabs-left-content{padding:50px 40px; width:calc(100% - 290px);}

.manage-projects .ant-table-tbody > tr > td img{border-radius:50%; height:30px; width:30px;}
.manage-projects .ant-table-thead .ant-table-thead > tr > th{padding:14px 18px;}

.ant-tabs .ant-tabs-left-bar{border:0 none}

.manage-projects .ant-table-content{color:#000; font-size:12px;}
.manage-projects .ant-table-thead{text-transform:uppercase;}
.manage-projects .ant-table-thead > tr > th{border-bottom:0px none; padding:14px 25px;}
.manage-projects .ant-table-tbody > tr > td{padding:20px 25px;}
.manage-projects tr > td:first-child{border-left:1px solid #e7e6e6;}
.manage-projects tr > td:last-child{border-right:1px solid #e7e6e6;}
.manage-projects tr:first-child > td{border-top:1px solid #e7e6e6;}
.manage-projects tr:last-child > td{border-bottom:1px solid #e7e6e6;}
.manage-projects .ant-table-content .btn{border-radius:0px;}
.btn-grey{background-color:#969696; color:#fff; font-weight:normal; font-size:12px; padding:2px 8px;}

.ant-table table tbody{border:1px solid #e7e6e6;}
.ant-table-pagination.ant-pagination{display:flex; float:none; justify-content:center;}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next{min-width:24px; height:24px;}
.ant-table-pagination.ant-pagination li{border:0px none;}
.ant-table-pagination.ant-pagination li a{border:0px none; border-radius:50%; display:flex; flex-direction:column; font-weight:bold; height:24px; justify-content:center; text-align:center; width:24px;}
.ant-table-pagination.ant-pagination .ant-pagination-item{line-height:24px; height:24px; min-width:24px; width:24px;}
.ant-table-pagination.ant-pagination .ant-pagination-prev a,
.ant-table-pagination.ant-pagination .ant-pagination-next a{color:#F07E22;}
.ant-table-pagination.ant-pagination .ant-pagination-disabled a{color:#cbcbcb; font-weight:600;}
.ant-table-pagination.ant-pagination .ant-pagination-item-active a{background-color:#F07E22; color:#fff;}
/*      Buyer Pages End     */


/*      Tacs Page Start     */

.ant-list-split ul li.ant-list-item{border-bottom:0px none;}

.light-grey-bg{background-color:#F8F8F8; padding:80px 0;}
.orng-text{color:#F07E22;}

.child-category-section.tac-tabs{padding:80px 0;}
.child-category-section.tac-tabs .ant-tabs-nav-operations{display:none;}
.tac-tabs .ant-tabs-nav-list{flex:auto; margin:0px; padding:24px;}
.cms-page .tac-tabs .ant-tabs-tab{border:0px none; flex:1 1; padding:16px;}
.tac-tabs .ant-tabs-tab.ant-tabs-tab-active{box-shadow:1px 3px 10px #E6E6E6;}
.cms-page .tac-tabs .ant-tabs-tab .ant-tabs-tab-btn{color:#6E939C; font-size:19px; font-weight:500; padding:0px; width:100%;}
.tac-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color:#003965;}
.tac-tabs .ant-tabs-tab .image{display:flex; justify-content:center; flex-direction:column; align-items:center;height:56px; margin-bottom:10px;}
.child-category-section.tac-tabs .ant-tabs-tab img,
.child-category-section.tac-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn img,
.child-category-section.tac-tabs .ant-tabs-tab:hover .ant-tabs-tab-btn img{width:auto;}
.tac-tabs .ant-tabs-tab:hover .ant-tabs-tab-btn{color:#FC9E00;}
.child-category-section.tac-tabs .ant-tabs-tab:hover .ant-tabs-tab-btn img{filter:grayscale(1);}

.child-category-section.tac-tabs .ant-tabs-tabpane{padding:32px 48px;}
.child-category-section.tac-tabs .ant-tabs-tabpane .heading{margin-top:20px; margin-bottom:10px;}
.child-category-section.tac-tabs .ant-tabs-tabpane ul{color:#003965; display:flex; flex-wrap:wrap; font-size:16px; justify-content:space-between; list-style-type:disc; margin:16px 0 10px 30px; padding-left:48px;}
.child-category-section.tac-tabs .ant-tabs-tabpane ul li{margin:4px 0; width:48%;}

.challenges-block{padding:48px 0;}
.challenges-block .text-block{width:58%;}
.cms-page .inner-page .challenges-block .heading.lg{margin-bottom:10px;}
.challenges-block .blocks{display:flex; flex-wrap:wrap; justify-content:space-between;}
.challenges-block .block{flex-basis:50%; margin-top:32px; padding-right:48px;}
.challenges-block .block .title{color:#962C08; font-size:24px; font-weight:600; line-height:1.3; margin-bottom:10px; margin-top:20px;}
.challenges-block .block .text{color:#4E413B; font-size:16px; font-weight:500;}
.challenges-block .img-block{position:relative; width:42%;}
.challenges-block .img-block img{max-width:unset; position:absolute; right:-130px; top:-70px; width:650px;}

.gigfy-block,
.candidate-assess-block{padding:80px 0;}
.gigfy-block > .container:first-child{border-bottom:1px solid #EAEAEA; padding-bottom:80px;}
.gigfy-block .right-block{width:41.5%;}
.gigfy-block .content{color:#828282; font-size:24px; font-weight:500; line-height:36px;}

.reviews-block{padding-top:80px;}
.reviews-block .block{width:45%;}
.reviews-block .subtitle{color:#F07E22; font-size:18px; font-weight:500; line-height:1.1; margin:10px 0;}
.reviews-block .title::before{background-image:url(/static/media/quotes.410b22f9.svg); content:''; display:block;  height:41px;position:absolute; left:0px; top:0px; width:44px;}
.reviews-block .title{color:#464E5F; font-size:24px; font-weight:600; line-height:1.2em; margin-bottom:16px; padding-left:58px; position:relative;}

.value-prop-block .text-block{width:33%;}

.candidate-assess-block{text-align:center;}
.cms-page .inner-page .candidate-assess-block .heading.lg{margin-bottom:80px;}
.tac-steps{align-items:flex-start; font-weight:600; color:#003965; position:relative;}
.tac-steps::before{border-top:dashed 2px #A3A3A350; content:''; display:block; left:10%; position:absolute; top:20px; width:80%; z-index:0;}
.candidate-assess-block .block{align-items:center; display:flex; flex-basis:20%; flex-direction:column; justify-content:center; padding:0 32px; z-index:1;}
.candidate-assess-block .block .num{background-color:#fff; border-radius:50%; box-shadow:1px 2px 10px #00000010; display:flex; flex-direction:column; font-size:16px; height:42px; justify-content:center; margin-bottom:30px; width:42px;}
.candidate-assess-block .block{align-items:center; display:flex; flex-basis:20%; flex-direction:column; justify-content:center; padding:0 32px; z-index:1;}
.candidate-assess-block .block .img-block{align-items:center; background-color:#fff1da; border-radius:50%; -o-border-radius:50%; -moz-border-radius:50%; -webkit-border-radius:50%; display:flex; flex-direction:column; justify-content:center; height:65px; overflow:hidden; width:65px;}
.candidate-assess-block .block .title{font-size:20.8px; font-weight:700; line-height:1.3; margin-top:20px; margin-bottom:10px;}

.tsa-block .text-block{align-self:center; background-color:#fbf2eb; padding:64px; width:50%;}
.value-prop-block .ant-avatar-icon,
.tsa-block .ant-avatar-icon{background-color:transparent; color:#f07e22; font-weight:600; height:auto; line-height:1; width:auto;}
.value-prop-block .ant-list-item,
.tsa-block .ant-list-item{padding-top:0px; padding-bottom:10px;}
.value-prop-block .ant-list-item-meta-description,
.tsa-block .ant-list-item-meta-description{color:#4E413B; font-size:17.6px; font-weight:500;}
.cms-page .inner-page .value-prop-block .heading.lg,
.cms-page .inner-page .tsa-block .heading.lg{margin-bottom:20px;}

.ai-grey-block{background-color:#928A87; padding:48px 0;}
.ai-grey-block .heading{color:#fff;}
.ai-grey-block .blocks{margin-top:48px;}
.ai-grey-block .block{background-color:#fff; border-radius:8px; -o-border-radius:8px; -moz-border-radius:8px; -webkit-border-radius:8px; padding:24px; text-align:center; width:calc(25% - 22px);}
.ai-grey-block .block .title{color:#F07E22; font-size:18px; font-weight:600;}
.ai-grey-block .block .text{color:#4E413B; font-size:16px; font-weight:500; margin-bottom:10px;}

.hiw-block{padding:80px 0;}
.hiw-block .text-block{max-width:100%; padding:0 15px; width:585px;}
.hiw-block ul li.ant-list-item{margin:16px 0; padding:0px;}
.hiw-block ul .ant-avatar.ant-avatar-icon{background-color:#FFF1DA; color: #F07E22; font-weight:bold; height:30px; width:30px;}
.hiw-block ul .ant-list-item-meta-description{color:#4E413B; font-size:16px; font-weight:500;}
/*      Tacs Page End       */


@media only screen and (max-width:767px){
.container{max-width:100%; padding:0 15px;}
.small-heading{font-size:18px;}
.heading{font-size:30px; line-height:1.2;}
.btn-brown{padding:7px 10px;}
.btn-white{padding:7px 10px;}

/*    Header Start        */
.header-block .second-block,
.header-block .third-block{display:none;}
.mega-menu .menu{width:calc(100% - 30px);}
.mega-menu .menu .sub-menu,
.mega-menu .menu .sub-menu-2{left:0px; padding-top:0px; position:relative;}
/*    Header End        */

/*    Footer Start        */
.ant-layout-footer .top-block{}
.ant-layout-footer .footer-block .block{margin-bottom:25px; padding:0px; width:100%;}
.ant-layout-footer .copyright .block{flex-direction:column;}
.ant-layout-footer .copyright .block .right{margin-top:15px;}
/*    Footer End        */


.banners .banners-block{background-position:25% 5%; height:300px; padding-top:50px;}
.banners .head-text{font-size:30px;}

.trusted-partners-section .container{flex-direction:column;}
.trusted-partners-section .left-block,
.trusted-partners-section .right-block{width:100%;}

.categories-section .categories-list .category-block{width:100%;}

.img-text-section{flex-direction:column;}
.img-text-section.rev{flex-direction:column-reverse;}
.img-text-section > div{width:100%;}
.img-text-section .text-block .block{padding:50px 20px;}
.img-text-section > div.img-block{min-height:300px;}


.sp-projects-block .nav-tabs .nav-link{font-size:16px; padding:8px 10px;}
.projects-listing .single-project .top-block,
.projects-listing .single-project .bottom-block{padding:15px 20px;}
.sp-list-block{flex-direction:column;}
.sp-list-block > div{width:100%;}
.sp-list-block > div:not(:last-child){border-bottom:1px solid #d1d1d1; border-right:0px none; padding:20px;}

.how-we-work-block{flex-direction:column;}
.how-we-work-block > .left{margin-bottom:20px; width:100%;}
.how-we-work-block > .right{width:100%;}
.how-we-work-block .blocks .left,
.how-we-work-block .blocks .right{max-width:48%;}
.how-we-work-block .block{padding:20px;}
.how-we-work-block .block-title{line-height:1.2;}

.money-protection-block .blocks{flex-direction:column;}
.money-protection-block .block{margin-bottom:20px; width:100%;}
}

